import React from "react";
import { Route, Switch } from "react-router-dom";
import "./App.css";
import LoginPage from "./pages/login/LoginPage";
import ProjectsPage from "./pages/projects/ProjectsPage";
import CreatePage from "./pages/create/CreatePage";
import BasketPage from "./pages/basket/BasketPage";
import RequestsPage from "./pages/requests/RequestsPage";
import RequestPage from "./pages/requests/request/RequestPage";
import OrdersPage from "./pages/orders/OrdersPage";
import OrderPage from "./pages/orders/order/OrderPage";
import ResponseInterceptor from "./components/ResponseInterceptor";
import ConsoleLogger from "./logs/ConsoleLogger";
import UserPanelPage from "./pages/user-panel/UserPanelPage";

function App(): React.ReactElement {
  ConsoleLogger("App refresh");

  return (
    <>
      <ResponseInterceptor />
      <Switch>
        <Route path="/requests/:requestId" component={RequestPage} />
        <Route path="/orders/:orderId" component={OrderPage} />
        <Route
          path="/projects/:projectId/baskets/:basketId"
          component={BasketPage}
        />
        <Route path="/projects" component={ProjectsPage} />
        <Route path="/requests" component={RequestsPage} />
        <Route path="/orders" component={OrdersPage} />
        <Route path="/create" component={CreatePage} />
        <Route path="/user-panel" component={UserPanelPage} />
        <Route path="/login" component={LoginPage} />
        <Route path="/" component={ProjectsPage} />
      </Switch>
    </>
  );
}

export default App;
