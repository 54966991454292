import { CancelTokenSource } from "axios";
import {
  CommentWithChilds,
  Comment,
} from "../shared/types/3yourmind/comment.type";
import { RequestForQuote } from "../shared/types/3yourmind/request-for-quote.type";
import { Request } from "../shared/types/3yourmind/request.type";
import { Order } from "../shared/types/3yourmind/order.type";
import { UserRequest } from "../shared/types/user/user-request.types";
import { authorizedAxios } from "./http-common";
import ConsoleLogger from "../logs/ConsoleLogger";

class RequestService {
  static async requestForQuote(
    basketId: number,
    message: string,
    isManualPricingRequired: boolean,
    amount: string,
    cancelTokenSource?: CancelTokenSource
  ): Promise<Request | Order> {
    const requestForQuote: RequestForQuote = {
      basketId,
      message,
      isManualPricingRequired,
      serviceId: 88,
      amount,
    };
    const result = await authorizedAxios.post(
      "api/requests/",
      requestForQuote,
      {
        cancelToken: cancelTokenSource?.token,
      }
    );

    let responseData;
    if (result.data.payment === undefined) {
      responseData = result.data as Request;
    } else {
      responseData = result.data as Order;
    }
    return Promise.resolve(responseData);
  }

  static async getRequests(
    cancelTokenSource?: CancelTokenSource
  ): Promise<Array<UserRequest>> {
    const result = await authorizedAxios.get("api/requests/", {
      cancelToken: cancelTokenSource?.token,
    });

    return Promise.resolve(result.data as Array<UserRequest>);
  }

  static async getRequest(
    requestId: number,
    cancelTokenSource?: CancelTokenSource
  ): Promise<Request> {
    let result;
    try {
      result = await authorizedAxios.get(`api/requests/${requestId}`, {
        cancelToken: cancelTokenSource?.token,
      });
    } catch (e: any) {
      ConsoleLogger(e);
      return Promise.reject(e);
    }
    return Promise.resolve(result.data as Request);
  }

  static async getComments(
    requestId: number,
    cancelTokenSource?: CancelTokenSource
  ): Promise<CommentWithChilds[]> {
    const result = await authorizedAxios.get(
      `api/requests/comments/${requestId}`
    );
    const comments: CommentWithChilds[] = result.data as CommentWithChilds[];
    const commentMap = new Map<number, CommentWithChilds>();

    comments.map((comment) => {
      const commentWithChild = comment;
      commentWithChild.childComments = [];
      commentMap.set(commentWithChild.id, commentWithChild);
      return commentWithChild;
    });

    comments.forEach((comment) => {
      if (comment.replyTo) {
        const parent = commentMap.get(comment.replyTo);
        parent?.childComments.push(comment);
      }
    });

    const filteredComments = comments.filter(
      (comment) => comment.replyTo === null
    );

    return Promise.resolve(filteredComments);
  }

  static async getFirstComment(
    requestId: number,
    cancelTokenSource?: CancelTokenSource
  ): Promise<Comment | void> {
    const result = await authorizedAxios.get(
      `api/requests/comments/${requestId}`
    );

    if (result && result.data) {
      const comments = result.data as Comment[];
      comments.sort(
        (a: Comment, b: Comment) =>
          new Date(a.createdTime).getTime() - new Date(b.createdTime).getTime()
      );
      return Promise.resolve(comments[0]);
    }
    return Promise.resolve();
  }
}

export default RequestService;
