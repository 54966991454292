import axios, { CancelTokenSource } from "axios";
import React, { useContext, useEffect, useState } from "react";
import ProjectService from "../../../../api/ProjectService";
import { Line } from "../../../../shared/types/3yourmind/line.type";
import { Material } from "../../../../shared/types/3yourmind/material.type";
import { globalContext } from "../../../../store/global-store";
import MaterialList from "../materials/MaterialList";
import ConsoleLogger from "../../../../logs/ConsoleLogger";

type SelectMaterialStepProps = {
  selectedLine: Line;
  basketId: number;
  onMaterialSelected: (material: Material) => void;
};

export default function SelectMaterialStep({
  selectedLine,
  basketId,
  onMaterialSelected,
}: SelectMaterialStepProps) {
  const { globalState } = useContext(globalContext);
  const [getMaterials, setMaterials] = useState<Array<Material>>([]);
  const [isLoading, setLoading] = useState<boolean>(false);

  const downloadMaterials = (cancelTokenSource: CancelTokenSource) => {
    if (selectedLine) {
      ProjectService.getMaterials(
        basketId,
        selectedLine.id,
        "PL",
        cancelTokenSource
      )
        .then((materials) => {
          setMaterials(materials);
          setLoading(false);
          ConsoleLogger(materials);
        })
        .catch((error: any) => {
          setLoading(false);
          ConsoleLogger(error);
        });
    }
  };

  useEffect(() => {
    const cancelTokenSource = axios.CancelToken.source();
    setLoading(true);
    downloadMaterials(cancelTokenSource);
    return () => {
      cancelTokenSource.cancel();
    };
  }, [selectedLine]);

  return (
    <MaterialList
      isMaterialsLoading={isLoading}
      materialList={getMaterials}
      onClick={onMaterialSelected}
    />
  );
}
