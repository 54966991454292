import {
  CircularProgress,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { UserRequest } from "../../shared/types/user/user-request.types";
import RequestService from "../../api/RequestService";
import ConsoleLogger from "../../logs/ConsoleLogger";
import PriceFormatter from "../../logic/PriceFormatter";
import PermanentDrawer from "../../components/Menu/PermanentDrawer";

export default function RequestsPage() {
  const history = useHistory();
  const location = useLocation();

  const [isLoading, setLoading] = useState<boolean>(true);
  const [getRequests, setRequests] = useState<Array<UserRequest>>([]);

  useEffect(() => {
    RequestService.getRequests()
      .then((result: Array<UserRequest>) => {
        setLoading(false);
        setRequests(result);
      })
      .catch((error: any) => {
        setLoading(false);
        const { message } = error;
        ConsoleLogger(message);
      });
    return () => {};
  }, []);

  const moveToRequestDetails = (requestId: number) => {
    history.push(`/requests/${requestId}`);
  };

  let content;
  if (isLoading) {
    content = <CircularProgress />;
  } else {
    content = (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="Tabelka">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: "bold" }}>Title</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Elements</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Price</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Created</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getRequests.map((request: UserRequest) => (
              <TableRow
                key={request.projectId}
                hover
                sx={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  // eslint-disable-next-line no-underscore-dangle
                  moveToRequestDetails(request._id);
                }}
              >
                <TableCell>{request.title}</TableCell>
                <TableCell>{request.numberOfLines}</TableCell>
                <TableCell>
                  {`${PriceFormatter.formatRequestPrice(request.totalPrice)} `}
                </TableCell>
                <TableCell>
                  {new Date(request.createdAt).toLocaleDateString()}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
  return (
    <PermanentDrawer>
      <Container
        maxWidth={false}
        sx={{
          marginTop: "1rem",
          marginBottom: "1rem",
          backgroundColor: "white",
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {" "}
        <h1>Requests</h1>
        {content}
      </Container>
    </PermanentDrawer>
  );
}
