import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import PasswordValidator from "../../validation/PasswordValidator";

type ChangePasswordDialogProps = {
  userId: string;
  username: string;
  isOpen: boolean;
  handleClose: () => void;
  handleSubmit: (userId: string, password: string) => void;
};

type ChangePasswordData = {
  password: string;
  confirmPassword: string;
};

export default function ChangePasswordDialog({
  userId,
  username,
  isOpen,
  handleClose,
  handleSubmit,
}: ChangePasswordDialogProps) {
  const [formValues, setFormValues] = useState<ChangePasswordData>({
    password: "",
    confirmPassword: "",
  });

  const [errorMessages, setErrorMessages] = useState<ChangePasswordData>({
    password: "",
    confirmPassword: "",
  });

  const clearFormData = () => {
    setErrorMessages({ password: "", confirmPassword: "" });
    setFormValues({ password: "", confirmPassword: "" });
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setFormValues({ ...formValues, [id]: value });
  };

  const validateData = () => {
    let isValid = true;

    const errors: ChangePasswordData = {
      password: "",
      confirmPassword: "",
    };

    if (PasswordValidator(formValues.password)) {
      errors.password = "";
    } else {
      isValid = false;
      errors.password =
        "Password must have minimun 8 characters, at least one letter and one number!";
    }

    if (formValues.password === formValues.confirmPassword) {
      errors.confirmPassword = "";
    } else {
      isValid = false;
      errors.confirmPassword = "Password should match";
    }

    setErrorMessages(errors);

    if (isValid) {
      handleSubmit(userId, formValues.password);
    }
  };

  useEffect(() => {
    clearFormData();
    return () => {};
  }, [isOpen]);

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>Change password for: {username}</DialogTitle>
      <DialogContent>
        <TextField
          error={errorMessages.password.length > 0}
          margin="dense"
          id="password"
          label="Password"
          type="password"
          value={formValues.password}
          onChange={handleInputChange}
          helperText={errorMessages.password}
          fullWidth
          inputProps={{ maxLength: 40 }}
          variant="outlined"
        />
        <TextField
          error={errorMessages.confirmPassword.length > 0}
          margin="dense"
          id="confirmPassword"
          label="Confirm Password"
          type="password"
          value={formValues.confirmPassword}
          onChange={handleInputChange}
          helperText={errorMessages.confirmPassword}
          fullWidth
          inputProps={{ maxLength: 40 }}
          variant="outlined"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={validateData}>Save</Button>
      </DialogActions>
    </Dialog>
  );
}
