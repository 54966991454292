import React, { useContext, useState } from "react";
import { TextField } from "mui-rff";
import { Form } from "react-final-form";
import Dialog from "@mui/material/Dialog";
import {
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  CssBaseline,
  Container,
  AvatarGroup,
  Grid,
  Theme,
  Avatar,
  Button,
  Checkbox,
  FormControlLabel,
  Link,
  Paper,
  Typography,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useHistory } from "react-router-dom";
import { globalContext } from "../../store/global-store";
import UserDataService from "../../api/UserDataService";
import { User } from "../../shared/types/user/user.types";
import ConsoleLogger from "../../logs/ConsoleLogger";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: "white",
    width: "100%",
  },
  paper: {
    marginTop: theme.spacing(8),
    padding: theme.spacing(8, 2, 8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

type FormData = {
  email: string;
  password: string;
};

const LoginPage = () => {
  const history = useHistory();
  const classes = useStyles();
  const { globalState, dispatch } = useContext(globalContext);
  const [errorDialog, setErrorDialog] = useState(false);

  const submitHandler = (values: FormData) => {
    // onLogin(values.email, values.password);
    UserDataService.login(values.email, values.password)
      .then((response) => {
        ConsoleLogger(response.data);
        const user: User = response.data.user as User;
        dispatch({ type: "SET_USER", payload: user });
        history.push("/");
      })
      .catch((e) => {
        setErrorDialog(true);
        ConsoleLogger(e);
      });
  };

  const handleCloseErrorDialog = () => {
    setErrorDialog(false);
  };

  return (
    <Container className={classes.container} component="main" maxWidth="xs">
      <CssBaseline />
      <Paper className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Form
          onSubmit={submitHandler}
          // eslint-disable-next-line no-unused-vars
          render={({ handleSubmit, values }) => (
            <form className={classes.form} onSubmit={handleSubmit} noValidate>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Sign In
              </Button>
            </form>
          )}
        />
      </Paper>
      <Dialog open={errorDialog} onClose={handleCloseErrorDialog}>
        <DialogTitle>Błąd!!</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Wystąpił błąd. Sprawdź dane i spróbuj ponownie
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseErrorDialog} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default LoginPage;
