import { Box, Typography } from "@mui/material";
import React from "react";
import { Comment } from "../../../shared/types/3yourmind/comment.type";

type CommentItemProps = {
  comment: Comment;
};

export default function CommentItem({ comment }: CommentItemProps) {
  return (
    <Box
      sx={{
        display: "flex",
        flex: "1 1 auto",
        flexDirection: "column",
        marginLeft: "1rem",
      }}
    >
      {" "}
      <Box sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
        <Typography sx={{ width: "50%", flexShrink: 0, fontWeight: "bold" }} />
        <Typography
          sx={{
            width: "50%",
            flexShrink: 0,
            textAlign: "right",
          }}
        >
          {new Date(comment.createdTime).toLocaleString()}
        </Typography>
      </Box>
      <Box sx={{ marginTop: "0.5rem" }}>{comment.message}</Box>
    </Box>
  );
}
