import { Box, Skeleton } from "@mui/material";
import React from "react";
import { Material } from "../../../../shared/types/3yourmind/material.type";
import MaterialItem from "./MaterialItem";

type MaterialListProps = {
  isMaterialsLoading: boolean;
  materialList: Array<Material>;
  onClick: (material: Material) => void;
};

export default function MaterialList({
  isMaterialsLoading,
  materialList,
  onClick,
}: MaterialListProps) {
  return (
    <Box>
      {isMaterialsLoading ? (
        <>
          <Skeleton variant="text" />
          <Skeleton variant="text" />
          <Skeleton variant="text" />
        </>
      ) : (
        materialList.map((material) => (
          <MaterialItem
            material={material}
            key={material.id}
            onClick={onClick}
          />
        ))
      )}
    </Box>
  );
}
