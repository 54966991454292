import { Box, CardMedia, Typography } from "@mui/material";
import React from "react";
import PriceFormatter from "../../../logic/PriceFormatter";
import { Line } from "../../../shared/types/3yourmind/line.type";

type LineRequestItemProps = {
  line: Line;
};

export default function LineRequestItem({ line }: LineRequestItemProps) {
  const sizeTemplate = `${parseFloat(line.stlFile!.parameter.w).toFixed(
    2
  )} x ${parseFloat(line.stlFile!.parameter.d).toFixed(2)} x ${parseFloat(
    line.stlFile!.parameter.h
  ).toFixed(2)} ${line.stlFile?.unit}`;

  const priceTemplate = PriceFormatter.formatPrice(
    line.itemTotalPrice,
    line.isManualPricingRequired,
    line.isPriceResolutionRequired
  );

  const unitPriceTemplate = line.unitPrice
    ? `${line.unitPrice.exclusiveTax} ${line.unitPrice.currency}`
    : "--";

  const unitPrintPriceTemplate = line.unitPrintPrice
    ? `${line.unitPrintPrice.exclusiveTax} ${line.unitPrintPrice.currency}`
    : "Pricing Required";

  const totalPriceTemplate = line.itemTotalPrice
    ? `${line.itemTotalPrice.exclusiveTax} ${line.itemTotalPrice.currency}`
    : "--";

  const postProcessingContent =
    line.postProcessings.length > 0 ? (
      <Box sx={{ marginBottom: "0.5rem" }}>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            position: "relative",
            boxSizing: "border-box",
          }}
        >
          <Typography sx={{ width: "50%", fontWeight: "bold" }}>
            Post Processing
          </Typography>
          <Typography
            sx={{ width: "50%", textAlign: "right", fontWeight: "bold" }}
          >
            Post Processing Price
          </Typography>
        </Box>
        {line.postProcessings.map((postProcessing) => (
          <Box key={postProcessing.postProcessing.id}>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                position: "relative",
                boxSizing: "border-box",
              }}
            >
              <Typography sx={{ width: "50%" }}>
                {postProcessing.postProcessing.title}
              </Typography>
              <Typography sx={{ width: "50%", textAlign: "right" }}>
                {postProcessing.price
                  ? `${postProcessing.price?.exclusiveTax} ${postProcessing.price?.currency}`
                  : `Pricing Required`}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    ) : (
      <></>
    );

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        marginTop: "2rem",
        paddingBottom: "2rem",
        paddingLeft: "2rem",
        paddingRight: "2rem",
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          minWidth: "100px",
          maxWidth: "150px",
          flexDirection: "column",
        }}
      >
        <Box>
          <CardMedia
            component="img"
            title={line.name}
            image={line.stlFile?.downloadThumbnailUrl}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flex: "1",
          flexDirection: "column",
          paddingLeft: "1rem",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography sx={{ fontWeight: "bold" }}>{line.fullName}</Typography>
        </Box>
        <Box
          sx={{
            display: "block",
            marginBlockStart: "1rem",
            marginBlockEnd: "1rem",
            marginInlineEnd: "0px",
            marginInlineStart: "0px",
            marginBottom: "0.5rem",
          }}
        >
          <Typography sx={{ fontWeight: "bold" }}>Size</Typography>
          <Typography>{sizeTemplate}</Typography>
        </Box>
        <Box sx={{ display: "contents" }}>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              position: "relative",
              boxSizing: "border-box",
            }}
          >
            <Typography sx={{ width: "50%", fontWeight: "bold" }}>
              Material
            </Typography>
            <Typography
              sx={{ width: "50%", textAlign: "right", fontWeight: "bold" }}
            >
              Printing Price
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              position: "relative",
              boxSizing: "border-box",
              marginBottom: "0.5rem",
            }}
          >
            <Typography sx={{ width: "50%" }}>{line.offer?.title}</Typography>
            <Typography sx={{ width: "50%", textAlign: "right" }}>
              {unitPrintPriceTemplate}
            </Typography>
          </Box>
          {postProcessingContent}
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              position: "relative",
              boxSizing: "border-box",
            }}
          >
            <Typography sx={{ width: "50%", fontWeight: "bold" }} />
            <Typography
              sx={{ width: "50%", textAlign: "right", fontWeight: "bold" }}
            >
              Unit Price
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              position: "relative",
              boxSizing: "border-box",
              marginBottom: "0.5rem",
            }}
          >
            <Typography sx={{ width: "50%" }} />
            <Typography sx={{ width: "50%", textAlign: "right" }}>
              {unitPriceTemplate}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              position: "relative",
              boxSizing: "border-box",
            }}
          >
            <Typography sx={{ width: "50%", fontWeight: "bold" }} />
            <Typography
              sx={{ width: "50%", textAlign: "right", fontWeight: "bold" }}
            >
              Quantity
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              position: "relative",
              boxSizing: "border-box",
              marginBottom: "0.5rem",
            }}
          >
            <Typography sx={{ width: "50%" }} />
            <Typography sx={{ width: "50%", textAlign: "right" }}>
              {line.quantity}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              position: "relative",
              boxSizing: "border-box",
            }}
          >
            <Typography sx={{ width: "50%", fontWeight: "bold" }} />
            <Typography
              sx={{ width: "50%", textAlign: "right", fontWeight: "bold" }}
            >
              Total Price
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              position: "relative",
              boxSizing: "border-box",
            }}
          >
            <Typography sx={{ width: "50%", fontWeight: "bold" }} />
            <Typography variant="h5" sx={{ width: "50%", textAlign: "right" }}>
              {totalPriceTemplate}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
