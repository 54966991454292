import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import RequestService from "../../../api/RequestService";
import OrderService from "../../../api/OrderService";
import PriceFormatter from "../../../logic/PriceFormatter";
import ConsoleLogger from "../../../logs/ConsoleLogger";
// import { Basket } from "../../../shared/types/3yourmind/basket.type";
import { Request } from "../../../shared/types/3yourmind/request.type";
import { Line } from "../../../shared/types/3yourmind/line.type";
import ConfirmOrderDialog from "./ConfirmOrderDialog";
import { User } from "../../../shared/types/user/user.types";

type RequestFooterProps = {
  // basket: Basket;
  request: Request;
  // lines: Array<Line>;
  loggedUser: User | undefined;
};

export default function RequestFooter({
  // basket,
  request,
  // lines,
  loggedUser,
}: RequestFooterProps) {
  const history = useHistory();
  const location = useLocation();

  const [isDialogOpen, setDialogOpen] = useState(false);

  const stringTemplate = PriceFormatter.formatPrice(
    request.totalPrice,
    false,
    false
  );

  const moveToOrderDetails = (orderId: number) => {
    // history.replace(`/orders/${orderId}`);
    history.replace(`/orders/`);
  };

  const confirmRequestClickHandler = () => {
    setDialogOpen(true);
  };

  const closeClickHandler = () => {
    setDialogOpen(false);
  };

  const onRequestAcceptHandler = (text: string) => {
    setDialogOpen(false);
    OrderService.orderFromQuote(
      request.quoteId || 0,
      text,
      request.totalPrice?.exclusiveTax ?? "0",
      88,
      request.id
    ).then((order) => {
      ConsoleLogger(order);
      moveToOrderDetails(order.id);
    });
  };

  // All lines of basket must be assigned to the same supplier
  // All items must have material assigned
  const validateOrder = () => {
    const priced = request.lines.every(
      (v) =>
        v.isManualPricingRequired === false ||
        v.isManualPricingRequired === undefined
    );
    const validStatus = request.status === "quoted";
    return priced && validStatus;
  };

  return (
    <Box
      sx={{
        display: "flex",
        flex: "0 0 auto",
        flexFlow: "row nowrap",
        alignContent: "center",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        height: "4rem",
        padding: "1rem",
        borderTop: "1px solid rgba(0, 0, 0, 0.12)",
      }}
    >
      <Box>
        <Typography variant="h6">{stringTemplate}</Typography>
      </Box>
      <Box>
        <Button
          onClick={confirmRequestClickHandler}
          variant="outlined"
          disabled={!validateOrder()}
        >
          Place order
        </Button>
      </Box>
      <ConfirmOrderDialog
        isOpen={isDialogOpen}
        onClose={closeClickHandler}
        onConfirm={onRequestAcceptHandler}
      />
    </Box>
  );
}
