import { CancelTokenSource } from "axios";
import {
  CommentWithChilds,
  Comment,
} from "../shared/types/3yourmind/comment.type";
import { RequestForOrder } from "../shared/types/3yourmind/request-for-order.type";
import { Order } from "../shared/types/3yourmind/order.type";
import { UserOrder } from "../shared/types/user/user-order.types";
import { authorizedAxios } from "./http-common";
import { UserDB } from "../shared/types/user/user.types";
import ConsoleLogger from "../logs/ConsoleLogger";

class RequestService {
  static async orderFromQuote(
    quoteId: number,
    message: string,
    amount: string,
    serviceId: number,
    requestId: number,
    cancelTokenSource?: CancelTokenSource
  ): Promise<Order> {
    const requestForOrder: RequestForOrder = {
      quoteId,
      message,
      isManualPricingRequired: false,
      amount,
      serviceId,
      requestId,
    };

    const result = await authorizedAxios.post("api/orders/", requestForOrder, {
      cancelToken: cancelTokenSource?.token,
    });

    return Promise.resolve(result.data as Order);
  }

  static async getOrders(
    cancelTokenSource?: CancelTokenSource
  ): Promise<Array<UserOrder>> {
    const result = await authorizedAxios.get("api/orders/", {
      cancelToken: cancelTokenSource?.token,
    });
    return Promise.resolve(result.data as Array<UserOrder>);
  }

  static async getOrder(
    orderId: number,
    cancelTokenSource?: CancelTokenSource
  ): Promise<Order> {
    const result = await authorizedAxios.get(`api/orders/${orderId}`, {
      cancelToken: cancelTokenSource?.token,
    });

    return Promise.resolve(result.data as Order);
  }

  static async getOrderComments(
    orderId: number,
    cancelTokenSource?: CancelTokenSource
  ): Promise<CommentWithChilds[]> {
    const result = await authorizedAxios.get(`api/orders/comments/${orderId}`);
    const comments: CommentWithChilds[] = result.data as CommentWithChilds[];
    const commentMap = new Map<number, CommentWithChilds>();

    comments.map((comment) => {
      const commentWithChild = comment;
      commentWithChild.childComments = [];
      commentMap.set(commentWithChild.id, commentWithChild);
      return commentWithChild;
    });

    comments.forEach((comment) => {
      if (comment.replyTo) {
        const parent = commentMap.get(comment.replyTo);
        parent?.childComments.push(comment);
      }
    });

    const filteredComments = comments.filter(
      (comment) => comment.replyTo === null
    );

    return Promise.resolve(filteredComments);
  }

  static async getFirstOrderComment(
    orderId: number,
    cancelTokenSource?: CancelTokenSource
  ): Promise<Comment> {
    const result = await authorizedAxios.get(`api/orders/comments/${orderId}`);

    const comments = result.data as Comment[];

    comments.sort(
      (a: Comment, b: Comment) =>
        new Date(a.createdTime).getTime() - new Date(b.createdTime).getTime()
    );

    return Promise.resolve(comments[0]);
  }
}

export default RequestService;
