/* eslint-disable no-unused-vars */
import { Box, Button, CardMedia, Typography } from "@mui/material";
import React from "react";
import ProjectService from "../../api/ProjectService";
import CountSelector from "../../components/CountSelector";
import PriceFormatter from "../../logic/PriceFormatter";
import ConsoleLogger from "../../logs/ConsoleLogger";
import { Line } from "../../shared/types/3yourmind/line.type";

type LineItemProps = {
  line: Line;
  basketId: number;
  isSelected: boolean;
  onLineUpdated: (line: Line) => void;
  onSelectLine: (line: Line) => void;
};

export default function LineItem({
  line,
  basketId,
  isSelected,
  onLineUpdated,
  onSelectLine,
}: LineItemProps) {
  const size = `${parseFloat(line.file.parameter.w).toFixed(2)} x ${parseFloat(
    line.file.parameter.d
  ).toFixed(2)} x ${parseFloat(line.file.parameter.h).toFixed(2)} ${
    line.file.unit
  }`;

  let postProcessingsTemplate = "";
  line.postProcessings.forEach(({ postProcessing, color }, index) => {
    let processingTemplate = postProcessing.title;
    if (color) {
      processingTemplate += ` (${color.title})`;
    }
    postProcessingsTemplate += processingTemplate;
    if (index !== line.postProcessings.length - 1) {
      postProcessingsTemplate += ", ";
    }
  });

  const quantityChangedHandler = (quantity: number) => {
    ProjectService.patchLineQuantity(basketId, line.id, quantity)
      .then((updatedLine: Line) => {
        onLineUpdated(updatedLine);
      })
      .catch((e: any) => {
        ConsoleLogger(e);
      });
  };

  let content;
  if (line.file.status === "finished") {
    content = (
      <Box
        sx={{
          display: "flex",
          alignItems: "stretch",
          lineHeight: "1rem",
          borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
          borderLeft: isSelected ? "8px solid rgba(0, 0, 0, 0.12)" : undefined,
        }}
        onClick={() => {
          onSelectLine(line);
        }}
      >
        <Box
          sx={{
            maxWidth: "200px",
            alignSelf: "stretch",
            width: "7rem",
            padding: ".4rem",
            cursor: "pointer",
          }}
        >
          <CardMedia
            component="img"
            title={line.name}
            image={line.file.downloadThumbnailUrl}
          />
        </Box>
        <Box
          sx={{
            width: "72%",
            justifyContent: "space-between",
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            ml: "1rem",
            pt: ".5rem",
          }}
        >
          <Box sx={{ display: "flex" }}>
            <Box
              sx={{ flexGrow: 1, overflow: "hidden", textOverflow: "ellipsis" }}
            >
              <h3>{line.fullName}</h3>
            </Box>
          </Box>
          <Box
            sx={{ flexGrow: 1, overflow: "hidden", textOverflow: "ellipsis" }}
          >
            <Typography>
              {line?.productName ?? "No Material Configured"}
            </Typography>
            <Typography>{size}</Typography>
            <Typography>{postProcessingsTemplate}</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "1rem",
              marginRight: "1rem",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Typography sx={{ marginRight: "0.5rem" }}>Unit:</Typography>
              <Typography sx={{ fontWeight: "bold" }}>
                {PriceFormatter.formatPrice(
                  line.unitPrice,
                  line.isManualPricingRequired,
                  line.isPriceResolutionRequired
                )}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Typography sx={{ marginRight: "0.5rem" }}>Total:</Typography>
              <Typography sx={{ fontWeight: "bold" }}>
                {PriceFormatter.formatPrice(
                  line.linePrice,
                  line.isManualPricingRequired,
                  line.isPriceResolutionRequired
                )}
              </Typography>
            </Box>
            <CountSelector
              initialCount={line.quantity}
              onCountChange={quantityChangedHandler}
            />
          </Box>
        </Box>
      </Box>
    );
  } else {
    content = (
      <Box>
        sx=
        {{
          display: "flex",
          borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        }}
        <Typography>File is in {line.file.status} state</Typography>
      </Box>
    );
  }
  return <>{content}</>;
}
