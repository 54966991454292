import React,{FC} from 'react';
import {CircularProgress, Dialog, DialogContent, DialogTitle} from "@mui/material";

interface RequestOrderProgressDialogProps {
  isOpen: boolean
}

const RequestOrderProgressDialog:FC<RequestOrderProgressDialogProps> = ({isOpen}) => (
  <Dialog open={isOpen}>
    <DialogTitle>Creating your order</DialogTitle>
    <DialogContent sx={{
      display:'flex',
        alignItems:'center',
        justifyContent:'center'
    }}>
      <CircularProgress/>
    </DialogContent>
  </Dialog>
)

export default RequestOrderProgressDialog;
