/* eslint-disable comma-dangle */
import { /* CancelToken */ CancelTokenSource } from "axios";
import { Basket } from "../shared/types/3yourmind/basket.type";
import { Line } from "../shared/types/3yourmind/line.type";
import { Material } from "../shared/types/3yourmind/material.type";
import { Offer } from "../shared/types/3yourmind/offer.type";
import { PostProcessingsPrice } from "../shared/types/3yourmind/post-processing-price.type";
import { RequestAddPostProcessings } from "../shared/types/3yourmind/request-add-postprocessings.type";
import { Project } from "../shared/types/user/project.types";
import { authorizedAxios } from "./http-common";

class ProjectService {
  static async getProjects(
    cancelTokenSource?: CancelTokenSource
  ): Promise<Array<Project>> {
    const result = await authorizedAxios.get("api/projects/", {
      cancelToken: cancelTokenSource?.token,
    });
    return Promise.resolve(result.data as Array<Project>);
  }

  static async removeProject(
    projectId: string,
    cancelTokenSource?: CancelTokenSource
  ): Promise<boolean> {
    const result = await authorizedAxios.delete(`api/projects/${projectId}`, {
      cancelToken: cancelTokenSource?.token,
    });
    return Promise.resolve(result.status === 204);
  }

  static async getBasketDetails(
    basketId: number,
    cancelTokenSource?: CancelTokenSource
  ): Promise<Basket> {
    const result = await authorizedAxios.get(
      `api/projects/baskets/${basketId}/`,
      { cancelToken: cancelTokenSource?.token }
    );
    return Promise.resolve(result.data as Basket);
  }

  static async getLines(
    basketId: number,
    cancelTokenSource?: CancelTokenSource
  ): Promise<Array<Line>> {
    const result = await authorizedAxios.get(
      `api/projects/baskets/${basketId}/lines/`,
      { cancelToken: cancelTokenSource?.token }
    );
    return Promise.resolve(result.data as Array<Line>);
  }

  static async getMaterials(
    basketId: number,
    lineId: number,
    country: string,
    cancelTokenSource?: CancelTokenSource
  ): Promise<Material[]> {
    const result = await authorizedAxios.get(
      `api/projects/baskets/${basketId}/lines/${lineId}/materials/?country=${country}`,
      { cancelToken: cancelTokenSource?.token }
    );
    return Promise.resolve(result.data as Array<Material>);
  }

  static async getOffers(
    basketId: number,
    lineId: number,
    materialId: number,
    country: string,
    cancelTokenSource?: CancelTokenSource
  ): Promise<Offer[]> {
    const result = await authorizedAxios.get(
      `api/projects/baskets/${basketId}/lines/${lineId}/materials/${materialId}/offers/?country=${country}`,
      { cancelToken: cancelTokenSource?.token }
    );
    return Promise.resolve(result.data as Array<Offer>);
  }

  static async getOfferPrice(
    basketId: number,
    lineId: number,
    materialId: number,
    offerId: number,
    postProcessingIds: Array<number>,
    country: string,
    cancelTokenSource?: CancelTokenSource
  ): Promise<PostProcessingsPrice> {
    const result = await authorizedAxios.get(
      `api/projects/baskets/${basketId}/lines/${lineId}/materials/${materialId}/offers/${offerId}/price/?postprocessings=${postProcessingIds.join()}&country=${country}`,
      { cancelToken: cancelTokenSource?.token }
    );

    return Promise.resolve(result.data as PostProcessingsPrice);
  }

  static async patchLineWithPostProcessing(
    basketId: number,
    lineId: number,
    requestAddPostProcessings: RequestAddPostProcessings,
    cancelTokenSource?: CancelTokenSource
  ): Promise<Line> {
    const result = await authorizedAxios.patch(
      `api/projects/baskets/${basketId}/lines/${lineId}/`,
      requestAddPostProcessings,
      { cancelToken: cancelTokenSource?.token }
    );

    return Promise.resolve(result.data as Line);
  }

  static async patchLineQuantity(
    basketId: number,
    lineId: number,
    quantity: number,
    cancelTokenSource?: CancelTokenSource
  ): Promise<Line> {
    const result = await authorizedAxios.patch(
      `api/projects/baskets/${basketId}/lines/${lineId}/`,
      { quantity },
      { cancelToken: cancelTokenSource?.token }
    );

    return Promise.resolve(result.data as Line);
  }
}

export default ProjectService;
