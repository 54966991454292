/* eslint-disable jsx-a11y/anchor-is-valid */
import { Box, Breadcrumbs, Link } from "@mui/material";
import React from "react";
import ConsoleLogger from "../../../logs/ConsoleLogger";
import { Line } from "../../../shared/types/3yourmind/line.type";
import { Material } from "../../../shared/types/3yourmind/material.type";
import PostProcessingStep from "./steps/PostProcessingStep";
import PrintingSettingsStep from "./steps/PrintingSettingsStep";
import SelectMaterialStep from "./steps/SelectMaterialStep";

export enum BasketStep {
  SELECT_MATERIAL,
  POST_PROCESSING,
  PRINTING_SETTINGS,
}

type SidebarStepsProps = {
  actualStep: BasketStep;
  basketId: number;
  selectedLine: Line;
  selectedMaterial: Material | null;
  postProcessingSelected: (material: Material) => void;
  selectMaterialSelectStep: () => void;
  selectPrintingSettingsStep: () => void;
  onLineUpdate: (line: Line) => void;
};

export default function SidebarSteps({
  actualStep,
  basketId,
  selectedLine,
  postProcessingSelected,
  selectedMaterial,
  selectMaterialSelectStep,
  selectPrintingSettingsStep,
  onLineUpdate,
}: SidebarStepsProps) {
  let content;
  let breadcrumbs;

  switch (actualStep) {
    case BasketStep.SELECT_MATERIAL: {
      breadcrumbs = (
        <Breadcrumbs>
          <Link color="inherit" component="button" underline="hover">
            Select Material
          </Link>
          {selectedLine.productId && selectedLine?.postProcessings && (
            <Link
              color="inherit"
              component="button"
              underline="hover"
              onClick={selectPrintingSettingsStep}
            >
              Printing Settings
            </Link>
          )}
        </Breadcrumbs>
      );
      content = (
        <SelectMaterialStep
          selectedLine={selectedLine}
          basketId={basketId}
          onMaterialSelected={postProcessingSelected}
        />
      );
      break;
    }
    case BasketStep.POST_PROCESSING: {
      breadcrumbs = (
        <Breadcrumbs>
          <Link
            color="inherit"
            component="button"
            underline="hover"
            onClick={selectMaterialSelectStep}
          >
            Select Material
          </Link>
          <Link color="inherit" component="button" underline="hover">
            Post-processing
          </Link>
        </Breadcrumbs>
      );
      if (selectedMaterial) {
        content = (
          <PostProcessingStep
            selectedMaterial={selectedMaterial}
            selectedLine={selectedLine}
            basketId={basketId}
            onLineUpdate={onLineUpdate}
          />
        );
      }
      break;
    }
    case BasketStep.PRINTING_SETTINGS: {
      breadcrumbs = (
        <Breadcrumbs>
          <Link
            color="inherit"
            component="button"
            underline="hover"
            onClick={selectMaterialSelectStep}
          >
            Select Material
          </Link>
          <Link color="inherit" component="button" underline="hover">
            Printing Settings
          </Link>
        </Breadcrumbs>
      );
      if (selectedLine) {
        content = <PrintingSettingsStep line={selectedLine} />;
      }
      break;
    }
    default: {
      break;
    }
  }
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          minHeight: "72px",
          padding: "1rem",
          borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        }}
      >
        {breadcrumbs}
      </Box>
      {content}
    </>
  );
}
