import React, { FC } from "react";
import { Box, Alert, Typography, AlertTitle, Button, Container } from "@mui/material";

interface ErrorPageProps {
  e: Error;
}

const ErrorPage:FC<ErrorPageProps> = ({e}) => (
    <Box sx={{height:'100vh', flexDirection:'column', display:'flex', justifyContent:'center'}}>
      <Container sx={{width:'60%',display:'flex',flexDirection:'column',alignItems:'center'}}>
        <Typography variant="h1">Oops</Typography>
        <Typography variant="subtitle1">An unknown error has occurred in the application.</Typography>
        <Alert severity="warning" sx={{width:'100%',margin:2}}>
          <AlertTitle>Error info</AlertTitle>
          {e.toString()}
        </Alert>
        <Box>
          <Button variant="contained" onClick={() => {
            window.location.href = "/";
          }}>Return to the main page</Button>
        </Box>
      </Container>
  </Box>
  )


export default ErrorPage;
