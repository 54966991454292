// standard email regex
const emailRegex = new RegExp(/^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i);

// at least one letter and number, min 8 characters, max 40 characters
const passwordRegex = new RegExp(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,40}$/i);

// all characters between 5-100
const customerNameRegex = new RegExp(/^.{5,100}$/i);

const userIdRegex = new RegExp(/^\d*[1-9]\d*$/);

export { emailRegex, passwordRegex, customerNameRegex, userIdRegex };
