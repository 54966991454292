import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React, { useContext } from "react";
import ProjectService from "../../api/ProjectService";
import { globalContext } from "../../store/global-store";
import ConsoleLogger from "../../logs/ConsoleLogger";

type ProjectNotFoundDialogProps = {
  projectId: string;
  isDialogOpen: boolean;
  onClose: () => void;
};

export default function ProjectNotFoundDialog({
  projectId,
  isDialogOpen,
  onClose,
}: ProjectNotFoundDialogProps) {
  const { globalState } = useContext(globalContext);
  const removeOrderHandler = () => {
    ProjectService.removeProject(projectId)
      .then((isSuccess) => {
        ConsoleLogger(isSuccess);
        onClose();
      })
      .catch(() => {
        ConsoleLogger("error");
        onClose();
      });
  };
  ConsoleLogger(`Not found dialog: ${isDialogOpen}`);
  return (
    <Dialog open={isDialogOpen} onClose={onClose}>
      <DialogTitle>Order not found</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Order not found in external database. Do you want to remove it from
          your account?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={removeOrderHandler} autoFocus>
          Remove
        </Button>
      </DialogActions>
    </Dialog>
  );
}
