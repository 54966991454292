import { AddCircleOutlined, SubjectOutlined } from "@mui/icons-material";
import {
  Drawer,
  List,
  ListItemText,
  Typography,
  ListItemIcon,
  ListItemButton,
  Box,
} from "@mui/material";
import React, { useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { globalContext } from "../../store/global-store";
import UserMenu from "./UserMenu";

type MenuProps = {
  children: any;
};

const drawerWidth = 240;

export default function PermanentDrawer({ children }: MenuProps) {
  const history = useHistory();
  const location = useLocation();

  const { globalState } = useContext(globalContext);

  let menuItems;

  if (globalState.loggedUser?.isAdmin) {
    menuItems = [
      {
        text: "Create Project",
        icon: <AddCircleOutlined color="primary" />,
        path: "/create",
      },
      {
        text: "Projects",
        icon: <SubjectOutlined color="primary" />,
        path: "/projects",
      },
      {
        text: "Requests",
        icon: <SubjectOutlined color="primary" />,
        path: "/requests",
      },
      {
        text: "Orders",
        icon: <SubjectOutlined color="primary" />,
        path: "/orders",
      },
      {
        text: "Users",
        icon: <SubjectOutlined color="primary" />,
        path: "/user-panel",
      },
    ];
  } else {
    menuItems = [
      {
        text: "Create Project",
        icon: <AddCircleOutlined color="primary" />,
        path: "/create",
      },
      {
        text: "Projects",
        icon: <SubjectOutlined color="primary" />,
        path: "/projects",
      },
      {
        text: "Requests",
        icon: <SubjectOutlined color="primary" />,
        path: "/requests",
      },
      {
        text: "Orders",
        icon: <SubjectOutlined color="primary" />,
        path: "/orders",
      },
    ];
  }

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "100%",
        position: "absolute",
      }}
    >
      <Drawer
        sx={{
          width: drawerWidth,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Box>
          <Typography
            variant="h5"
            sx={{
              padding: "1rem",
            }}
          >
            Technology Applied
          </Typography>
        </Box>

        <List>
          {menuItems.map((item) => (
            <ListItemButton
              key={item.text}
              onClick={() => history.push(item.path)}
              sx={{
                background:
                  location.pathname === item.path ? "#f4f4f4" : undefined,
              }}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          ))}
        </List>
        <UserMenu
          boxSx={{
            display: "flex",
            alignItems: "center",
            textAlign: "center",
            position: "fixed",
            bottom: 0,
            marginBottom: "10px",
            width: drawerWidth,
          }}
        />
      </Drawer>
      <>{children}</>
    </Box>
  );
}
