import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import React, { useState } from "react";
import ConsoleLogger from "../../../../logs/ConsoleLogger";
import { Color } from "../../../../shared/types/3yourmind/color.type";
import { PostProcessing } from "../../../../shared/types/3yourmind/post-processing.type";
import { SelectedPostProcessing } from "../../../../shared/types/3yourmind/selected-postprocessing.type";

type PostProcessingItemProps = {
  postProcessing: PostProcessing;
  onPostProcessingChecked: (
    selectedPostProcessing: SelectedPostProcessing,
    isChecked: boolean
  ) => void;
};

export default function OfferItem({
  postProcessing,
  onPostProcessingChecked,
}: PostProcessingItemProps) {
  const [isChecked, setChecked] = useState<boolean>(false);
  const [getColorId, setColorId] = useState<number | null>(null);

  const onCheckedHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);

    onPostProcessingChecked(
      { postProcessingId: postProcessing.id, colorId: getColorId },
      event.target.checked
    );
  };

  const onSelectColorHandler = (event: SelectChangeEvent) => {
    const colorId = parseInt(event.target.value, 10);
    setColorId(colorId);

    onPostProcessingChecked(
      { postProcessingId: postProcessing.id, colorId },
      isChecked
    );
  };
  return (
    <Box
      sx={{
        padding: ".8rem 1.4rem",
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
      }}
    >
      <FormControlLabel
        label={postProcessing.title}
        control={<Checkbox checked={isChecked} onChange={onCheckedHandler} />}
      />
      {postProcessing.colors && postProcessing.colors?.length > 0 && isChecked && (
        <Box>
          <FormControl fullWidth>
            <InputLabel>Color</InputLabel>
            <Select
              label="Color"
              onChange={onSelectColorHandler}
              value={getColorId?.toString() ?? ""}
            >
              {postProcessing.colors.map((color) => (
                <MenuItem value={color.id} key={color.id}>
                  {color.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      )}
    </Box>
  );
}
