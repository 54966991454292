import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";

type RequestOrderDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  onRequest: (text: string) => void;
};

export default function RequestOrderDialog({
  isOpen,
  onClose,
  onRequest,
}: RequestOrderDialogProps) {
  const [getMessage, setMessage] = useState("");

  const handleMessageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(event.target.value);
  };

  return (
    <Dialog open={isOpen}>
      <DialogTitle>Request price at Technology Applied</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Optionally add a message to your request
        </DialogContentText>
        <TextField
          autoFocus
          variant="outlined"
          value={getMessage}
          onChange={handleMessageChange}
          fullWidth
          multiline
          rows={10}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={() => {
            onRequest(getMessage);
          }}
        >
          Request
        </Button>
      </DialogActions>
    </Dialog>
  );
}
