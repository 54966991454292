import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CircularProgress,
  Container,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RequestService from "../../../api/RequestService";
import ConsoleLogger from "../../../logs/ConsoleLogger";
import {
  Comment,
  CommentWithChilds,
} from "../../../shared/types/3yourmind/comment.type";
import { Request } from "../../../shared/types/3yourmind/request.type";
import LineRequestItem from "./LineRequestItem";
import PriceFormatter from "../../../logic/PriceFormatter";
import CommentItem from "./CommentItem";
import PermanentDrawer from "../../../components/Menu/PermanentDrawer";
import RequestFooter from "./RequestFooter";
import { globalContext } from "../../../store/global-store";

export default function RequestPage() {
  const history = useHistory();
  const location = useLocation();

  const { requestId } = useParams<{
    requestId: string;
  }>();

  const { globalState } = useContext(globalContext);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [getRequestDetails, setRequestDetails] = useState<Request>();

  const [getFirstComment, setFirstComment] = useState<Comment | null>(null);

  const [itemsExpanded, setItemsExpanded] = useState<boolean>(true);
  const [summaryExpanded, setSummaryExpanded] = useState<boolean>(true);
  const [commentsExpanded, setCommentsExpanded] = useState<boolean>(false);

  const handleItemsExpand = () => {
    setItemsExpanded(!itemsExpanded);
  };

  const handleSummaryExpand = () => {
    setSummaryExpanded(!summaryExpanded);
  };

  const handleCommentsExpand = () => {
    setCommentsExpanded(!commentsExpanded);
  };

  useEffect(() => {
    RequestService.getRequest(parseInt(requestId, 10))
      .then((result: Request) => {
        setLoading(false);
        setRequestDetails(result);
      })
      .catch((error: any) => {
        setLoading(false);
        const { message } = error;
        ConsoleLogger(message);
        // eslint-disable-next-line
        window.alert("Error reading request");
        history.replace("/requests");
      });

    RequestService.getFirstComment(parseInt(requestId, 10)).then(
      (comment: Comment | void) => {
        if (comment) {
          setFirstComment(comment);
        } else {
          setFirstComment(null);
        }

        ConsoleLogger(comment);
      }
    );
    return () => {};
  }, []);

  let content;
  if (isLoading) {
    content = (
      <Box
        sx={{
          width: "100%",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  } else if (getRequestDetails) {
    content = (
      <Box>
        <h2>{getRequestDetails.fullNumber}</h2>
        <Card
          sx={{
            display: "flex",
            flex: "1 1 auto",
            flexDirection: "column",
            marginBottom: "1rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              paddingLeft: "1rem",
              paddingRight: "1rem",
              paddingTop: "1rem",
            }}
          >
            <Typography
              sx={{ width: "25%", flexShrink: 0, fontWeight: "bold" }}
            >
              Request Id
            </Typography>
            <Typography
              sx={{ width: "25%", flexShrink: 0, fontWeight: "bold" }}
            >
              Status
            </Typography>
            <Typography
              sx={{
                width: "50%",
                flexShrink: 0,
                fontWeight: "bold",
                textAlign: "right",
              }}
            >
              Date Created
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              paddingLeft: "1rem",
              paddingRight: "1rem",
              paddingBottom: "1rem",
            }}
          >
            <Typography sx={{ width: "25%", flexShrink: 0 }}>
              {getRequestDetails.id}
            </Typography>
            <Typography sx={{ width: "25%", flexShrink: 0 }}>
              {getRequestDetails.status}
            </Typography>
            <Typography
              sx={{
                width: "50%",
                flexShrink: 0,
                textAlign: "right",
              }}
            >
              {new Date(getRequestDetails.createdAt).toLocaleString()}
            </Typography>
          </Box>
        </Card>
        <Accordion expanded={itemsExpanded} onChange={handleItemsExpand}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h5" sx={{ width: "33%", flexShrink: 0 }}>
              Items
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{ width: "33%", flexShrink: 0 }} />
            {getRequestDetails.lines.map((line) => (
              <LineRequestItem line={line} key={line.id} />
            ))}
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={summaryExpanded} onChange={handleSummaryExpand}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h5" sx={{ width: "33%", flexShrink: 0 }}>
              Summary
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ display: "block" }}>
              <Box
                sx={{
                  position: "relative",
                  boxSizing: "border-box",
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "flexEnd",
                }}
              >
                <Box sx={{ justifyContent: "flex-end", width: "50%" }} />
                <Box sx={{ flex: "none", width: "50%", paddingRight: "2rem" }}>
                  <Box sx={{ paddingBottom: "0.5rem" }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        position: "relative",
                        boxSizing: "border-box",
                      }}
                    >
                      <Typography sx={{ width: "50%", fontWeight: "bold" }}>
                        Material
                      </Typography>
                      <Typography
                        sx={{
                          width: "50%",
                          textAlign: "right",
                          fontWeight: "bold",
                        }}
                      >
                        Printing Price
                      </Typography>
                    </Box>
                    {getRequestDetails.materialPriceBreakdown.map(
                      ({ title, totalPrice }) => (
                        <Box
                          key={title}
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            position: "relative",
                            boxSizing: "border-box",
                          }}
                        >
                          <Typography sx={{ width: "50%" }}>{title}</Typography>
                          <Typography
                            sx={{
                              width: "50%",
                              textAlign: "right",
                            }}
                          >
                            {`${totalPrice.exclusiveTax} ${totalPrice.currency}`}
                          </Typography>
                        </Box>
                      )
                    )}
                  </Box>
                  <Box
                    sx={{
                      paddingBottom: "0.5rem",
                      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        position: "relative",
                        boxSizing: "border-box",
                      }}
                    >
                      <Typography sx={{ width: "50%", fontWeight: "bold" }}>
                        Post Processing
                      </Typography>
                      <Typography
                        sx={{
                          width: "50%",
                          textAlign: "right",
                          fontWeight: "bold",
                        }}
                      >
                        Post Processing Price
                      </Typography>
                    </Box>
                    {getRequestDetails.postProcessingPriceBreakdown.map(
                      ({ title, isManuallyPriced, totalPrice }) => (
                        <Box
                          key={title}
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            position: "relative",
                            boxSizing: "border-box",
                          }}
                        >
                          <Typography sx={{ width: "50%" }}>{title}</Typography>
                          <Typography
                            sx={{
                              width: "50%",
                              textAlign: "right",
                            }}
                          >
                            {PriceFormatter.formatPrice(
                              totalPrice,
                              isManuallyPriced,
                              false
                            )}
                          </Typography>
                        </Box>
                      )
                    )}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      position: "relative",
                      boxSizing: "border-box",
                    }}
                  >
                    <Typography sx={{ width: "50%", fontWeight: "bold" }} />
                    <Typography
                      sx={{
                        width: "50%",
                        textAlign: "right",
                        fontWeight: "bold",
                      }}
                    >
                      Total Price
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      position: "relative",
                      boxSizing: "border-box",
                    }}
                  >
                    <Typography sx={{ width: "50%" }} />
                    <Typography
                      variant="h4"
                      sx={{
                        width: "50%",
                        textAlign: "right",
                      }}
                    >
                      {getRequestDetails.totalPrice
                        ? `${getRequestDetails.totalPrice.exclusiveTax} ${getRequestDetails.totalPrice.currency}`
                        : "--"}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={commentsExpanded} onChange={handleCommentsExpand}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h5" sx={{ width: "33%", flexShrink: 0 }}>
              Comments
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {getFirstComment && <CommentItem comment={getFirstComment} />}
          </AccordionDetails>
        </Accordion>
        <RequestFooter
          // basket={getBasketDetails}
          request={getRequestDetails}
          // lines={getLines}
          loggedUser={globalState.loggedUser}
        />
      </Box>
    );
  }

  return (
    <PermanentDrawer>
      <Container
        maxWidth={false}
        sx={{
          marginTop: "1rem",
          marginBottom: "1rem",
          backgroundColor: "white",
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {content}
      </Container>
    </PermanentDrawer>
  );
}
