import { Box, CircularProgress } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import ProjectService from "../../../../api/ProjectService";
import ConsoleLogger from "../../../../logs/ConsoleLogger";
import { Line } from "../../../../shared/types/3yourmind/line.type";
import { Material } from "../../../../shared/types/3yourmind/material.type";
import { Offer } from "../../../../shared/types/3yourmind/offer.type";
import { PostProcessing } from "../../../../shared/types/3yourmind/post-processing.type";
import { globalContext } from "../../../../store/global-store";
import PostProcessingList from "../postprocessing/PostProcessingList";

type PostProcessingStepProps = {
  selectedMaterial: Material;
  basketId: number;
  selectedLine: Line;
  onLineUpdate: (line: Line) => void;
};

export default function PostProcessingStep({
  selectedMaterial,
  basketId,
  selectedLine,
  onLineUpdate,
}: PostProcessingStepProps) {
  const { globalState } = useContext(globalContext);
  const [getOffers, setOffers] = useState<Array<Offer>>([]);
  const [isLoading, setLoading] = useState<boolean>(false);

  const downloadOffers = () => {
    setLoading(true);
    ProjectService.getOffers(
      basketId,
      selectedLine.id,
      selectedMaterial.id,
      "PL"
    )
      .then((offers) => {
        setOffers(offers);
        setLoading(false);
        ConsoleLogger(offers);
      })
      .catch((error: any) => {
        setLoading(false);
        ConsoleLogger(error);
      });
  };

  const selectPostProcessingHandler = (postProcessing: PostProcessing) => {};

  useEffect(() => {
    downloadOffers();
    return () => {};
  }, [selectedMaterial]);

  let content;
  if (isLoading) {
    content = (
      <Box
        sx={{
          height: "100%",
          display: "grid",
          placeItems: "center",
          placeContent: "center",
          overflow: "hidden",
          pt: "1rem",
          pb: "1rem",
        }}
      >
        <CircularProgress />
      </Box>
    );
  } else if (getOffers[0]) {
    content = (
      <PostProcessingList
        postProcessingList={getOffers[0].postProcessings}
        basketId={basketId}
        lineId={selectedLine.id}
        materialId={selectedMaterial.id}
        offerId={getOffers[0].id}
        selectedMaterial={selectedMaterial}
        onClick={selectPostProcessingHandler}
        onLineUpdate={onLineUpdate}
      />
    );
  }

  return <Box>{content}</Box>;
}
