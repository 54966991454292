import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import RequestService from "../../api/RequestService";
import PriceFormatter from "../../logic/PriceFormatter";
import ConsoleLogger from "../../logs/ConsoleLogger";
import { Basket } from "../../shared/types/3yourmind/basket.type";
import { Line } from "../../shared/types/3yourmind/line.type";
import RequestOrderDialog from "./RequestOrderDialog";
import { User } from "../../shared/types/user/user.types";
import RequestOrderProgressDialog from "./RequestOrderProgressDialog";

type BasketFooterProps = {
  basket: Basket;
  lines: Array<Line>;
  loggedUser: User | undefined;
};

export default function BasketFooter({
  basket,
  lines,
  loggedUser,
}: BasketFooterProps) {
  const history = useHistory();
  const location = useLocation();

  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isOrdering, setIsOrdering] = useState<boolean>(false);

  const stringTemplate = PriceFormatter.formatPrice(
    basket.subtotal,
    basket.isManualPricingRequired,
    basket.isPriceResolutionRequired
  );
  const moveToRequestDetails = (requestId: number) => {
    history.replace(`/requests/${requestId}`);
  };

  const moveToOrderDetails = (orderId: number) => {
    history.replace(`/orders/${orderId}`);
  };

  const continueClickHandler = () => {
    setDialogOpen(true);
  };

  const closeClickHandler = () => {
    setDialogOpen(false);
  };

  const onRequestAcceptHandler = (text: string) => {
    setDialogOpen(false);
    setIsOrdering(true);
    RequestService.requestForQuote(
      basket.id,
      text,
      basket.isManualPricingRequired,
      basket.subtotalWithoutDiscount?.exclusiveTax ??
        basket.subtotal?.exclusiveTax
    ).then((request) => {
      ConsoleLogger(request);
      if (request.salesTransactionType === "request") {
        moveToRequestDetails(request.id);
      } else {
        moveToOrderDetails(request.id);
      }
    }); /*
    .catch(() => {
      setIsOrdering(false);
    });
    */
  };

  // All lines of basket must be assigned to the same supplier
  // All items must have material assigned
  const validateBasket = () => {
    const sameSupplier = lines.every(
      (v, i, a) => v.partner?.id === a[0].partner?.id
    );

    const allItemsHasMaterial = lines.every((v, i, a) => v.productId);
    return sameSupplier && allItemsHasMaterial;
  };

  return (
    <>
      <RequestOrderProgressDialog isOpen={isOrdering} />
      <Box
        sx={{
          display: "flex",
          flex: "0 0 auto",
          flexFlow: "row nowrap",
          alignContent: "center",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          height: "4rem",
          padding: "1rem",
          borderTop: "1px solid rgba(0, 0, 0, 0.12)",
        }}
      >
        <Box>
          <Typography variant="h6">{stringTemplate}</Typography>
        </Box>
        <Box>
          <Button
            onClick={continueClickHandler}
            variant="outlined"
            disabled={!validateBasket()}
          >
            Continue
          </Button>
        </Box>
        <RequestOrderDialog
          isOpen={isDialogOpen}
          onClose={closeClickHandler}
          onRequest={onRequestAcceptHandler}
        />
      </Box>
    </>
  );
}
