import EditIcon from "@mui/icons-material/Edit";
import { Box, Button, IconButton, Typography } from "@mui/material";
import React from "react";
import { Basket } from "../../shared/types/3yourmind/basket.type";

type BasketHeaderProps = {
  basket: Basket;
  isAddNewItemVisible: boolean;
  onAddItemClicked: () => void;
};

export default function BasketHeader({
  basket,
  isAddNewItemVisible,
  onAddItemClicked,
}: BasketHeaderProps) {
  const fullTitle = `${basket.number} ${basket.title}`.replace(/\s+/g, "-");
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
        justifyContent: "space-between",
        width: "100%",
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        padding: "1rem",
      }}
    >
      <Box
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "row",
          flexWrap: "initial",
          alignItems: "center",
        }}
      >
        <Typography>{fullTitle}</Typography>
        <IconButton disabled aria-label="Example" color="secondary">
          <EditIcon />
        </IconButton>
      </Box>
      <Box>
        <Button
          sx={{ margin: ".4rem" }}
          variant="outlined"
          onClick={onAddItemClicked}
        >
          {!isAddNewItemVisible ? "Add new item" : "Close"}
        </Button>
      </Box>
    </Box>
  );
}
