import { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { authorizedAxios } from "../api/http-common";
import ConsoleLogger from "../logs/ConsoleLogger";

export default function ResponseInterceptor() {
  const interceptorId = useRef<number | null>(null);
  const history = useHistory();

  useEffect(() => {
    interceptorId.current = authorizedAxios.interceptors.response.use(
      undefined,
      (error) => {
        ConsoleLogger(error);
        switch (error.response.status) {
          case 401:
            history.replace("/login");
            break;
          default:
            break;
        }
      }
    );

    return () => {
      if (interceptorId.current !== null) {
        authorizedAxios.interceptors.response.eject(interceptorId.current);
      }
    };
  }, []);

  return null;
}
