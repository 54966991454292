import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { Material } from "../../../../shared/types/3yourmind/material.type";

type MaterialItemProps = {
  material: Material;
  onClick: (material: Material) => void;
};

export default function MaterialItem({ material, onClick }: MaterialItemProps) {
  const productionDays = `Produced in ${material.minProductionDays}-${material.maxProductionDays} days`;

  return (
    <Box
      sx={{
        display: "flex",
        alignContent: "center",
        justifyContent: "space-between",
        padding: ".8rem 1.4rem",
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
      }}
    >
      <Box>
        <Typography>{material.fullProductTitle}</Typography>
        <Typography>{productionDays}</Typography>
      </Box>
      <Box>
        <Button
          variant="outlined"
          onClick={() => {
            onClick(material);
          }}
        >
          Select
        </Button>
      </Box>
    </Box>
  );
}
