/* eslint-disable no-unused-vars */
import {
  CircularProgress,
  Container,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import ProjectService from "../../api/ProjectService";
import PermanentDrawer from "../../components/Menu/PermanentDrawer";
import PriceFormatter from "../../logic/PriceFormatter";
import { Project } from "../../shared/types/user/project.types";

export default function ProjectsPage() {
  const history = useHistory();
  // const location = useLocation();

  const [isLoading, setLoading] = useState<boolean>(true);
  const [getProjects, setProjects] = useState<Array<Project>>([]);
  const [, /* getErrorMessage */ setErrorMessage] = useState<
    string | undefined
  >(undefined);

  useEffect(() => {
    let isComponentStillMounted = true;

    ProjectService.getProjects()
      .then((result: Array<Project>) => {
        if (!isComponentStillMounted) {return}
        setLoading(false);
        setProjects(result);
      })
      .catch((error: any) => {
        const { message } = error;
        setErrorMessage(message);
        if (!isComponentStillMounted) {return}
        setLoading(false);
      });

    return () => {
      // Cleanup
      // Do not update the state when user is no longer on the page
      isComponentStillMounted = false;
    };
  }, []);

  const moveToBasketDetails = (projectId: string, basketId: number) => {
    history.push(`/projects/${projectId}/baskets/${basketId}`);
  };

  let content;
  if (isLoading) {
    content = <CircularProgress />;
  } else {
    content = (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="Tabelka">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: "bold" }}>Tytuł</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Elementy</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Cena</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Zmodyfikowano</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getProjects.map((project: Project) => (
              <TableRow
                key={project.basketId}
                sx={{
                  cursor: "pointer",
                }}
                hover
                onClick={() => {
                  // eslint-disable-next-line no-underscore-dangle
                  moveToBasketDetails(project._id, project.basketId);
                }}
              >
                <TableCell>{project.basketTitle}</TableCell>
                <TableCell>{project.numberOfFiles}</TableCell>
                <TableCell>
                  {PriceFormatter.formatPrice(
                    project.subtotal,
                    project.isManualPricingRequired,
                    project.isPriceResolutionRequired
                  )}
                </TableCell>
                <TableCell>
                  {new Date(project.updatedTime).toLocaleDateString()}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  return (
    <PermanentDrawer>
      <Container
        maxWidth={false}
        sx={{
          marginTop: "1rem",
          marginBottom: "1rem",
          backgroundColor: "white",
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {" "}
        <h1>Projects</h1>
        {content}
      </Container>
    </PermanentDrawer>
  );
}
