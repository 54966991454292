/* eslint-disable @typescript-eslint/no-unused-expressions */
import { AxiosResponse } from "axios";
import { authorizedAxios } from "./http-common";

class FileService {
  static uploadFile(
    filename: string,
    unit: string,
    origin: string,
    file: File,
    onUploadProgress: (progressEvent: any) => void,
    basketId?: number | null,
    projectId?: string | null
  ): Promise<AxiosResponse<any>> {
    const formData = new FormData();

    formData.append("filename", filename);
    formData.append("unit", unit);
    formData.append("origin", origin);
    basketId && formData.append("basketId", basketId.toString());
    projectId && formData.append("projectId", projectId);
    formData.append("file", file);

    return authorizedAxios.post("api/files/", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    });
  }

  static async getFileStatus(fileId: string): Promise<string> {
    const result = await authorizedAxios.get(`api/files/status/${fileId}/`);

    return Promise.resolve(result.data.status.toString());
  }
}

export default FileService;
