import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { createTheme } from '@mui/material';
import { ThemeProvider } from '@mui/styles';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { GlobalStore } from './store/global-store';
import ErrorPage from "./pages/error/ErrorPage";

const theme = createTheme();

Sentry.init({
  dsn: "https://f856521f05004182af9cb78cdb38ff7e@o1384342.ingest.sentry.io/6702582",
  integrations: [new BrowserTracing()],
  tracesSampleRate: 0,
});

ReactDOM.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={({error}) => (<ErrorPage e={error} />)}>
      <BrowserRouter>
        <GlobalStore>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </GlobalStore>
      </BrowserRouter>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
