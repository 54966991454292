import { Box, CardMedia, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { Line } from "../../../shared/types/3yourmind/line.type";
import SidebarSteps, { BasketStep } from "./SidebarSteps";
import { Material } from "../../../shared/types/3yourmind/material.type";
import ConsoleLogger from "../../../logs/ConsoleLogger";

type BasketSidebarProps = {
  selectedLine: Line | null;
  basketId: number;
  onLineUpdate: (line: Line) => void;
};

export default function BasketSidebar({
  selectedLine,
  basketId,
  onLineUpdate,
}: BasketSidebarProps) {
  const selectStartState = () => {
    if (selectedLine?.productId && selectedLine?.postProcessings) {
      return BasketStep.PRINTING_SETTINGS;
    }
    return BasketStep.SELECT_MATERIAL;
  };

  const [getSelectedSidebarStep, selectSidebarStep] = useState(
    selectStartState
  );

  const [getSelectedMaterial, selectMaterial] = useState<Material | null>(null);

  const postProcessingSelectedHandler = (material: Material) => {
    selectSidebarStep(BasketStep.POST_PROCESSING);
    selectMaterial(material);
  };

  const selectMateriaSelectStep = () => {
    ConsoleLogger("Select Material Step");
    selectSidebarStep(BasketStep.SELECT_MATERIAL);
    selectMaterial(null);
  };

  const selectPostProcessingStep = () => {
    ConsoleLogger("Select Post Processing Step");
    selectSidebarStep(BasketStep.POST_PROCESSING);
  };

  const selectPrintingSettingsStep = () => {
    ConsoleLogger("Select Printing Settings Step");
    selectSidebarStep(BasketStep.PRINTING_SETTINGS);
  };

  useEffect(() => {
    selectSidebarStep(selectStartState());
  }, [selectedLine]);

  const onLineUpdateHandler = (line: Line) => {
    onLineUpdate(line);
    selectPrintingSettingsStep();
  };

  let content;
  if (selectedLine) {
    content = (
      <Box
        sx={{
          display: "flex",
          flex: "0 1 35%",
          flexFlow: "column nowrap",
          height: "inherit",
        }}
      >
        {/* Box for picture */}
        <Box>
          <Box
            sx={{
              display: "grid",
              placeItems: "center",
              placeContent: "center",
              pt: "1rem",
              pb: "1rem",
              cursor: "pointer",
              overflow: "hidden",
              borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
            }}
          >
            <CardMedia
              sx={{ maxWidth: "11rem", height: "auto" }}
              component="img"
              title={selectedLine.name}
              image={selectedLine.file.downloadThumbnailUrl}
            />
          </Box>
        </Box>

        {/* Box for content */}
        <Box sx={{ overflow: "auto" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              minHeight: "72px",
              padding: "1rem",
              borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
            }}
          >
            <Typography>{selectedLine.id}</Typography>
            <IconButton disabled aria-label="Example" color="secondary">
              <EditIcon />
            </IconButton>
          </Box>
          <SidebarSteps
            actualStep={getSelectedSidebarStep}
            basketId={basketId}
            selectedLine={selectedLine}
            postProcessingSelected={postProcessingSelectedHandler}
            selectedMaterial={getSelectedMaterial}
            selectMaterialSelectStep={selectMateriaSelectStep}
            selectPrintingSettingsStep={selectPrintingSettingsStep}
            onLineUpdate={onLineUpdateHandler}
          />
        </Box>
      </Box>
    );
  } else {
    content = (
      <>
        <h3>No item selected</h3>
      </>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        flex: "0 1 35%",
        flexFlow: "column nowrap",
        width: "35%",
        height: "inherit",
      }}
    >
      {content}
    </Box>
  );
}
