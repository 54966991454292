import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CircularProgress,
  Container,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import OrderService from "../../../api/OrderService";
import ConsoleLogger from "../../../logs/ConsoleLogger";
import {
  Comment,
  CommentWithChilds,
} from "../../../shared/types/3yourmind/comment.type";
import { Order } from "../../../shared/types/3yourmind/order.type";
import LineOrderItem from "./LineOrderItem";
import PriceFormatter from "../../../logic/PriceFormatter";
import CommentItem from "./CommentItem";
import PermanentDrawer from "../../../components/Menu/PermanentDrawer";

export default function OrderPage() {
  const history = useHistory();
  const location = useLocation();

  const { orderId } = useParams<{
    orderId: string;
  }>();

  const [isLoading, setLoading] = useState<boolean>(true);
  const [getOrderDetails, setOrderDetails] = useState<Order>();

  const [getFirstComment, setFirstComment] = useState<Comment | null>(null);

  const [itemsExpanded, setItemsExpanded] = useState<boolean>(true);
  const [summaryExpanded, setSummaryExpanded] = useState<boolean>(true);
  const [commentsExpanded, setCommentsExpanded] = useState<boolean>(false);

  const handleItemsExpand = () => {
    setItemsExpanded(!itemsExpanded);
  };

  const handleSummaryExpand = () => {
    setSummaryExpanded(!summaryExpanded);
  };

  const handleCommentsExpand = () => {
    setCommentsExpanded(!commentsExpanded);
  };

  useEffect(() => {
    OrderService.getOrder(parseInt(orderId, 10))
      .then((result: Order) => {
        setLoading(false);
        setOrderDetails(result);
      })
      .catch((error: any) => {
        setLoading(false);
        const { message } = error;
        ConsoleLogger(message);
      });

    OrderService.getFirstOrderComment(parseInt(orderId, 10)).then(
      (comment: Comment) => {
        setFirstComment(comment);
        ConsoleLogger(comment);
      }
    );
    return () => {};
  }, []);

  let content;
  if (isLoading) {
    content = (
      <Box
        sx={{
          width: "100%",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  } else if (getOrderDetails) {
    content = (
      <Box>
        <h2>{getOrderDetails.fullNumber}</h2>
        <Card
          sx={{
            display: "flex",
            flex: "1 1 auto",
            flexDirection: "column",
            marginBottom: "1rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              paddingLeft: "1rem",
              paddingRight: "1rem",
              paddingTop: "1rem",
            }}
          >
            <Typography
              sx={{ width: "25%", flexShrink: 0, fontWeight: "bold" }}
            >
              Order Id
            </Typography>
            <Typography
              sx={{ width: "25%", flexShrink: 0, fontWeight: "bold" }}
            >
              Status
            </Typography>
            <Typography
              sx={{
                width: "50%",
                flexShrink: 0,
                fontWeight: "bold",
                textAlign: "right",
              }}
            >
              Date Created
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              paddingLeft: "1rem",
              paddingRight: "1rem",
              paddingBottom: "1rem",
            }}
          >
            <Typography sx={{ width: "25%", flexShrink: 0 }}>
              {getOrderDetails.id}
            </Typography>
            <Typography sx={{ width: "25%", flexShrink: 0 }}>
              {getOrderDetails.status?.name}
            </Typography>
            <Typography
              sx={{
                width: "50%",
                flexShrink: 0,
                textAlign: "right",
              }}
            >
              {new Date(getOrderDetails.createdAt).toLocaleString()}
            </Typography>
          </Box>
        </Card>
        <Accordion expanded={itemsExpanded} onChange={handleItemsExpand}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h5" sx={{ width: "33%", flexShrink: 0 }}>
              Items
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{ width: "33%", flexShrink: 0 }} />
            {getOrderDetails.lines.map((line) => (
              <LineOrderItem line={line} key={line.id} />
            ))}
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={summaryExpanded} onChange={handleSummaryExpand}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h5" sx={{ width: "33%", flexShrink: 0 }}>
              Summary
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ display: "block" }}>
              <Box
                sx={{
                  position: "relative",
                  boxSizing: "border-box",
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "flexEnd",
                }}
              >
                <Box sx={{ justifyContent: "flex-end", width: "50%" }} />
                <Box sx={{ flex: "none", width: "50%", paddingRight: "2rem" }}>
                  <Box sx={{ paddingBottom: "0.5rem" }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        position: "relative",
                        boxSizing: "border-box",
                      }}
                    >
                      <Typography sx={{ width: "50%", fontWeight: "bold" }}>
                        Material
                      </Typography>
                      <Typography
                        sx={{
                          width: "50%",
                          textAlign: "right",
                          fontWeight: "bold",
                        }}
                      >
                        Printing Price
                      </Typography>
                    </Box>
                    {getOrderDetails.materialPriceBreakdown.map(
                      ({ title, totalPrice }) => (
                        <Box
                          key={title}
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            position: "relative",
                            boxSizing: "border-box",
                          }}
                        >
                          <Typography sx={{ width: "50%" }}>{title}</Typography>
                          <Typography
                            sx={{
                              width: "50%",
                              textAlign: "right",
                            }}
                          >
                            {`${totalPrice.exclusiveTax} ${totalPrice.currency}`}
                          </Typography>
                        </Box>
                      )
                    )}
                  </Box>
                  <Box
                    sx={{
                      paddingBottom: "0.5rem",
                      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        position: "relative",
                        boxSizing: "border-box",
                      }}
                    >
                      <Typography sx={{ width: "50%", fontWeight: "bold" }}>
                        Post Processing
                      </Typography>
                      <Typography
                        sx={{
                          width: "50%",
                          textAlign: "right",
                          fontWeight: "bold",
                        }}
                      >
                        Post Processing Price
                      </Typography>
                    </Box>
                    {getOrderDetails.postProcessingPriceBreakdown.map(
                      ({ title, totalPrice }) => (
                        <Box
                          key={title}
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            position: "relative",
                            boxSizing: "border-box",
                          }}
                        >
                          <Typography sx={{ width: "50%" }}>{title}</Typography>
                          <Typography
                            sx={{
                              width: "50%",
                              textAlign: "right",
                            }}
                          >
                            {PriceFormatter.formatPrice(
                              totalPrice,
                              false,
                              false
                            )}
                          </Typography>
                        </Box>
                      )
                    )}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      position: "relative",
                      boxSizing: "border-box",
                    }}
                  >
                    <Typography sx={{ width: "50%", fontWeight: "bold" }} />
                    <Typography
                      sx={{
                        width: "50%",
                        textAlign: "right",
                        fontWeight: "bold",
                      }}
                    >
                      Total Price
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      position: "relative",
                      boxSizing: "border-box",
                    }}
                  >
                    <Typography sx={{ width: "50%" }} />
                    <Typography
                      variant="h4"
                      sx={{
                        width: "50%",
                        textAlign: "right",
                      }}
                    >
                      {getOrderDetails.totalPrice
                        ? `${getOrderDetails.totalPrice.exclusiveTax} ${getOrderDetails.totalPrice.currency}`
                        : "--"}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={commentsExpanded} onChange={handleCommentsExpand}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h5" sx={{ width: "33%", flexShrink: 0 }}>
              Comments
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {getFirstComment && <CommentItem comment={getFirstComment} />}
          </AccordionDetails>
        </Accordion>
      </Box>
    );
  }

  return (
    <PermanentDrawer>
      <Container
        maxWidth={false}
        sx={{
          marginTop: "1rem",
          marginBottom: "1rem",
          backgroundColor: "white",
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {content}
      </Container>
    </PermanentDrawer>
  );
}
