import { Box, Button, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import ProjectService from "../../../../api/ProjectService";
import PriceFormatter from "../../../../logic/PriceFormatter";
import ConsoleLogger from "../../../../logs/ConsoleLogger";
import { Line } from "../../../../shared/types/3yourmind/line.type";
import { Material } from "../../../../shared/types/3yourmind/material.type";
import { PostProcessingsPrice } from "../../../../shared/types/3yourmind/post-processing-price.type";
import { PostProcessing } from "../../../../shared/types/3yourmind/post-processing.type";
import { RequestAddPostProcessings } from "../../../../shared/types/3yourmind/request-add-postprocessings.type";
import { SelectedPostProcessing } from "../../../../shared/types/3yourmind/selected-postprocessing.type";
import { globalContext } from "../../../../store/global-store";
import PostProcessingItem from "./PostProcessingItem";

type PostProcessingListProps = {
  basketId: number;
  lineId: number;
  materialId: number;
  offerId: number;
  selectedMaterial: Material;
  postProcessingList: Array<PostProcessing>;
  onClick: (material: PostProcessing) => void;
  onLineUpdate: (line: Line) => void;
};

export default function PostProcessingList({
  basketId,
  lineId,
  materialId,
  offerId,
  selectedMaterial,
  postProcessingList,
  onClick,
  onLineUpdate,
}: PostProcessingListProps) {
  const [getPrice, setPrice] = useState<PostProcessingsPrice>();
  const [getSelectedProcessings, setSelectedProcessings] = useState<
    Array<SelectedPostProcessing>
  >([]);
  const [isAddPostProcessingLoading, setAddPostProcessingLoading] = useState(
    false
  );

  let priceTemplate = "";

  if (getPrice) {
    priceTemplate = PriceFormatter.formatPrice(
      getPrice.price,
      getPrice.isManualPricingRequired,
      getPrice.isPriceResolutionRequired
    );
  } else {
    priceTemplate = "0.00";
  }

  const getPostProcessingPrice = () => {
    const postProcessingIds = getSelectedProcessings.map(
      (selectedPostProcessing) => selectedPostProcessing.postProcessingId
    );

    ConsoleLogger(postProcessingIds);

    ProjectService.getOfferPrice(
      basketId,
      lineId,
      materialId,
      offerId,
      postProcessingIds,
      "PL"
    ).then((postProcessingPrice) => {
      ConsoleLogger(postProcessingPrice);
      setPrice(postProcessingPrice);
    });
  };

  const postProcessingCheckedHandler = (
    selectedPostProcessing: SelectedPostProcessing,
    isChecked: boolean
  ) => {
    const index = getSelectedProcessings.findIndex(
      (currentElement: SelectedPostProcessing) =>
        currentElement.postProcessingId ===
        selectedPostProcessing.postProcessingId
    );

    if (isChecked) {
      if (index === -1) {
        getSelectedProcessings.push(selectedPostProcessing);
      } else {
        getSelectedProcessings[index] = selectedPostProcessing;
      }
    } else if (index > -1) {
      getSelectedProcessings.splice(index, 1);
    }
    getPostProcessingPrice();
  };

  const addPostProcessingHandler = () => {
    const requestAddPostProcessing: RequestAddPostProcessings = {
      offerId,
      postProcessings: getSelectedProcessings,
    };
    ProjectService.patchLineWithPostProcessing(
      basketId,
      lineId,
      requestAddPostProcessing
    ).then((line) => {
      ConsoleLogger(line);
      onLineUpdate(line);
    });
  };

  useEffect(() => {
    getPostProcessingPrice();
    return () => {};
  }, []);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          columnGap: "1rem",
          justifyContent: "space-around",
          alignContent: "center",
          padding: ".8rem 1.4rem",
        }}
      >
        <Typography>Material: {selectedMaterial.fullProductTitle}</Typography>
        <Typography>Available Post-processing:</Typography>
      </Box>
      <Box
        sx={{
          paddingLeft: "2rem",
          paddingRight: "2rem",
        }}
      >
        {postProcessingList.map((postProcessing) => (
          <PostProcessingItem
            postProcessing={postProcessing}
            onPostProcessingChecked={postProcessingCheckedHandler}
            key={postProcessing.id}
          />
        ))}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "nowrap",
          justifyContent: "space-between",
          width: "100%",
          padding: "1rem",
          marginTop: "2rem",
          borderTop: "1px solid rgba(0, 0, 0, 0.12)",
        }}
      >
        <Box
          sx={{
            position: "relative",
            display: "flex",
            flexDirection: "row",
            flexWrap: "initial",
            alignItems: "center",
          }}
        >
          <Typography>{priceTemplate}</Typography>
        </Box>
        <Box>
          <Button
            disabled={getPrice === undefined}
            sx={{ margin: ".4rem" }}
            variant="outlined"
            onClick={addPostProcessingHandler}
          >
            Continue
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
