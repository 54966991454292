import { Price } from "../shared/types/3yourmind/price.type";

class PriceFormatter {
  static formatPrice(
    price: Price | null,
    isManualPricingRequired: boolean,
    isPriceResolutionRequired: boolean
  ): string {
    let priceTemplate = "";
    if (isManualPricingRequired) {
      priceTemplate = "Pricing Required";
    } else if (price) {
      priceTemplate = `${price.inclusiveTax} ${price.currency}`;
    }
    return priceTemplate;
  }

  static formatRequestPrice(price: Price | null): string {
    let priceTemplate = "";
    if (price === undefined || price === null) {
      priceTemplate = "Pricing Required";
    } else if (price) {
      priceTemplate = `${price.inclusiveTax} ${price.currency}`;
    }
    return priceTemplate;
  }
}

export default PriceFormatter;
