import {
  CircularProgress,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { UserOrder } from "../../shared/types/user/user-order.types";
import OrderService from "../../api/OrderService";
import ConsoleLogger from "../../logs/ConsoleLogger";
import PriceFormatter from "../../logic/PriceFormatter";
import PermanentDrawer from "../../components/Menu/PermanentDrawer";

export default function OrdersPage() {
  const history = useHistory();
  const location = useLocation();

  const [isLoading, setLoading] = useState<boolean>(true);
  const [getOrders, setOrders] = useState<Array<UserOrder>>([]);

  useEffect(() => {
    let isComponentStillMounted = true;

    OrderService.getOrders()
      .then((result: Array<UserOrder>) => {
        if (!isComponentStillMounted){return}
        setLoading(false);
        setOrders(result);
      })
      .catch((error: any) => {
        const { message } = error;
        ConsoleLogger(message);
        if (!isComponentStillMounted){return}
        setLoading(false);
      });

    return () => {
      // Cleanup
      // Do not update the state when user is no longer on the page
      isComponentStillMounted = false;
    };
  }, []);

  const moveToOrderDetails = (orderId: number) => {
    history.push(`/orders/${orderId}`);
  };

  let content;
  if (isLoading) {
    content = <CircularProgress />;
  } else {
    content = (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="Tabelka">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: "bold" }}>Title</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Elements</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Price</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Created</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getOrders.map((order: UserOrder) => (
              <TableRow
                key={order.projectId}
                hover
                sx={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  // eslint-disable-next-line no-underscore-dangle
                  moveToOrderDetails(order._id);
                }}
              >
                <TableCell>{order.title}</TableCell>
                <TableCell>{order.numberOfLines}</TableCell>
                <TableCell>
                  {`${order.subtotalPrice?.exclusiveTax} ${order.subtotalPrice?.currency} `}
                </TableCell>
                <TableCell>
                  {new Date(order.createdAt).toLocaleDateString()}
                </TableCell>
                <TableCell>{order.status ?? "unknown"}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
  return (
    <PermanentDrawer>
      <Container
        maxWidth={false}
        sx={{
          marginTop: "1rem",
          marginBottom: "1rem",
          backgroundColor: "white",
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {" "}
        <h1>Orders</h1>
        {content}
      </Container>
    </PermanentDrawer>
  );
}
