import React from "react";
import { Box, Typography } from "@mui/material";
import { Line } from "../../../../shared/types/3yourmind/line.type";

type PrintingSettingsStepProps = {
  line: Line;
};

export default function PrintingSettingsStep({
  line,
}: PrintingSettingsStepProps) {
  return (
    <Box>
      <Box sx={{ display: "flex", flexFlow: "column" }}>
        <Typography>Printing Settings</Typography>
        <Typography>Configure Material and Post-process</Typography>
      </Box>
    </Box>
  );
}
