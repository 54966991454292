import React, { useEffect } from "react";
import { Container } from "@mui/material";
import FileUploadBox from "./FileUploadBox";
import PermanentDrawer from "../../components/Menu/PermanentDrawer";
import { authorizedAxios } from "../../api/http-common";

export default function CreatePage() {
  /*
    Check whether the user is signed in.
    If not, the 401 code will be caught by ResponseInterceptor and user redirected to the login page.
  */
  useEffect(() => {
    authorizedAxios.get('/api/projects/create').then(() => {})
  },[])

  return (
    <PermanentDrawer>
      <Container
        maxWidth={false}
        sx={{
          marginTop: "6rem",
          backgroundColor: "white",
          width: "100%",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <FileUploadBox
          basketIdProp={null}
          projectIdProp={null}
          onUploadCompleted={null}
        />
      </Container>
    </PermanentDrawer>
  );
}
