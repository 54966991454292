import { PanelUser, User } from "../shared/types/user/user.types";
import { authorizedAxios, unauthorizedAxios } from "./http-common";

class UserDataService {
  static login(username: string, password: string) {
    return unauthorizedAxios.post("api/user/login", { username, password });
  }

  static async getAllUsers(): Promise<PanelUser[]> {
    const result = await authorizedAxios.get("api/user/user-panel/");
    return Promise.resolve(result.data as PanelUser[]);
  }

  static async register(
    username: string,
    password: string,
    isAdmin: boolean,
    customerName: string,
    firmaoUserId: number
  ) {
    const result = await unauthorizedAxios.post("api/user/register", {
      username,
      password,
      isAdmin,
      customerName,
      firmaoUserId,
    });
    if (result.data.success === true) {
      return Promise.resolve(result.data.user as User);
    }
    return Promise.reject(result.data.message);
  }

  static async updateUser(userId: string, data: any) {
    const result = await authorizedAxios.patch(
      `api/user/user-panel/${userId}`,
      data
    );

    return Promise.resolve(result.data);
  }

  static async changePassword(userId: string, password: string) {
    const result = await authorizedAxios.post(
      `api/user/user-panel/change-password/${userId}`,
      { password }
    );

    return Promise.resolve(result.data);
  }
}

export default UserDataService;
