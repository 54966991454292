/* eslint-disable no-unused-vars */
import { Box, CircularProgress, Container } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import ProjectService from "../../api/ProjectService";
import { Basket } from "../../shared/types/3yourmind/basket.type";
import { Line } from "../../shared/types/3yourmind/line.type";
import { globalContext } from "../../store/global-store";
import BasketBody from "./BasketBody";
import BasketFooter from "./BasketFooter";
import BasketHeader from "./BasketHeader";
import BasketSidebar from "./basket-sidebar/BasketSidebar";
import ProjectNotFoundDialog from "../../components/dialogs/ProjectNotFoundDialog";
import SidebarSteps, { BasketStep } from "./basket-sidebar/SidebarSteps";
import FileUploadBox from "../create/FileUploadBox";
import PermanentDrawer from "../../components/Menu/PermanentDrawer";
import ConsoleLogger from "../../logs/ConsoleLogger";

export default function BasketPage() {
  const history = useHistory();
  const location = useLocation();

  const { basketId, projectId } = useParams<{
    basketId: string;
    projectId: string;
  }>();

  const { globalState } = useContext(globalContext);
  const [isLoading, setLoading] = useState(true);
  const [getBasketDetails, setBasketDetails] = useState<Basket>();
  const [getLines, setLines] = useState<Array<Line>>([]);
  const [getSelectedLine, setSelectedLine] = useState<Line | null>(null);
  const [isProjectNotFound, setProjectNotFound] = useState<boolean>(false);
  const [isAddItemVisible, setAddItemVisible] = useState<boolean>(false);

  const downloadData = () => {
    Promise.all([
      ProjectService.getBasketDetails(parseInt(basketId, 10)),
      ProjectService.getLines(parseInt(basketId, 10)),
    ])
      .then(([basket, lines]) => {
        setBasketDetails(basket);
        setLines(lines);
        ConsoleLogger(lines);
        setLoading(false);
      })
      .catch((error: any) => {
        setLoading(false);
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 404) {
            ConsoleLogger("Order not found");
            setProjectNotFound(true);
          }
        }
      });
  };

  useEffect(() => {
    ConsoleLogger(globalState.loggedUser);
    setLoading(true);
    downloadData();
    return () => {};
  }, []);

  const selectLineHandler = (line: Line) => {
    setSelectedLine(line);
  };

  const lineUpdateHandler = (line: Line) => {
    downloadData();
    const lineToUpdate = getLines.findIndex(
      (findLine) => findLine.id === line.id
    );
    const newLines = [...getLines];

    newLines[lineToUpdate] = line;
    setLines(newLines);
  };

  const addItemHandler = () => {
    setAddItemVisible(!isAddItemVisible);
  };

  const uploadCompletedHandler = () => {
    downloadData();
    setAddItemVisible(false);
  };
  let content;
  if (isLoading) {
    content = (
      <Box
        sx={{
          width: "100%",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  } else if (getBasketDetails) {
    content = (
      <Box
        sx={{
          display: "flex",
          flex: "1 0 0",
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flex: "1 0 65%",
            flexFlow: "column",
            alignItems: "stretch",
            width: "65%",
            borderRight: "1px solid rgba(0, 0, 0, 0.12)",
            height: "100%",
          }}
        >
          <BasketHeader
            basket={getBasketDetails}
            isAddNewItemVisible={isAddItemVisible}
            onAddItemClicked={addItemHandler}
          />
          {isAddItemVisible && (
            <FileUploadBox
              projectIdProp={projectId}
              basketIdProp={parseInt(basketId, 10)}
              onUploadCompleted={uploadCompletedHandler}
            />
          )}
          <BasketBody
            basket={getBasketDetails}
            lines={getLines}
            selectedLine={getSelectedLine}
            onLineUpdated={lineUpdateHandler}
            onSelectLine={selectLineHandler}
          />
          <BasketFooter
            basket={getBasketDetails}
            lines={getLines}
            loggedUser={globalState.loggedUser}
          />
        </Box>
        <BasketSidebar
          selectedLine={getSelectedLine}
          basketId={getBasketDetails.id}
          onLineUpdate={lineUpdateHandler}
        />
      </Box>
    );
  }

  return (
    <PermanentDrawer>
      {content}
      <ProjectNotFoundDialog
        projectId={projectId}
        isDialogOpen={isProjectNotFound}
        onClose={() => {
          setProjectNotFound(false);
          history.goBack();
        }}
      />
    </PermanentDrawer>
  );
}
