import React, {
  createContext,
  ReactElement,
  ReactNode,
  useEffect,
  useReducer,
  useRef,
} from "react";

import Reducer, { initialState } from "./global-state-reducer";
import { ContextType, GlobalStateInterface } from "./global-state";
import ConsoleLogger from "../logs/ConsoleLogger";

function initializeState() {
  if (typeof Storage === "undefined") {
    throw new Error("You need to enable Storage to run this app.");
  }
  const fromLocalStorage = JSON.parse(
    localStorage.getItem("globalState") as string
  );

  return fromLocalStorage || initialState;
}

export const globalContext: React.Context<ContextType> = createContext(
  {} as ContextType
);

export function GlobalStore({
  children,
}: {
  children: ReactNode;
}): ReactElement {
  const [globalState, dispatch] = useReducer(Reducer, initializeState());
  const initialRenderGlobalState = useRef(true);
  const initialRenderPersistanceType = useRef(true);

  useEffect(() => {
    ConsoleLogger(`REFRESHING STATE: ${JSON.stringify(globalState)}`);
    if (initialRenderGlobalState.current) {
      initialRenderGlobalState.current = false;
      return;
    }
    localStorage.setItem("globalState", JSON.stringify(globalState));
  }, [globalState]);

  return (
    <globalContext.Provider value={{ globalState, dispatch }}>
      {children}
    </globalContext.Provider>
  );
}
