import { ActionType, GlobalStateInterface } from "./global-state";

export const initialState: GlobalStateInterface = {
  loggedUser: undefined,
};

const Reducer = (state: GlobalStateInterface, action: ActionType) => {
  switch (action.type) {
    case "SET_USER":
      return {
        ...state,
        loggedUser: action.payload,
      };
    case "LOAD_USER":
      return {
        ...state,
        loggedUser: action.payload,
      };
    case "PURGE_STATE":
      return initialState;
    default:
      return state;
  }
};

export default Reducer;
