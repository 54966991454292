import {
  Alert,
  AlertColor,
  Box,
  Button,
  Container,
  Snackbar,
} from "@mui/material";
import {
  DataGrid,
  GridCallbackDetails,
  GridCellEditCommitParams,
  GridColDef,
  GridPreProcessEditCellProps,
  GridRenderCellParams,
  MuiBaseEvent,
  MuiEvent,
} from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import UserDataService from "../../api/UserDataService";
import PermanentDrawer from "../../components/Menu/PermanentDrawer";
import ConsoleLogger from "../../logs/ConsoleLogger";
import { PanelUser, User } from "../../shared/types/user/user.types";
import CustomerNameValidator from "../../validation/CustomerNameValidator";
import EmailValidator from "../../validation/EmailValidator";
import UserIdValidator from "../../validation/UserIdValidator";
import AddUserDialog, { AddUserFormValues } from "./AddUserDialog";
import ChangePasswordDialog from "./ChangePasswordDialog";

type Message = {
  isOpen: boolean;
  severity: AlertColor;
  message: string;
};

type PasswordDialog = {
  isOpen: boolean;
  userId: string;
  username: string;
};

export default function UserPanelPage() {
  const [users, setUsers] = useState<PanelUser[]>([]);
  const [isAddUserDialogOpen, setAddUserDialogOpen] = useState<boolean>(false);
  const [
    changePasswordDialog,
    setChangePasswordDialog,
  ] = useState<PasswordDialog>({ isOpen: false, userId: "", username: "" });
  const [isLoading, setLoading] = useState<boolean>(true);
  const [message, setMessage] = useState<Message>({
    isOpen: false,
    severity: "info",
    message: "",
  });

  ConsoleLogger("UserPanel Refresh");

  const getAllUsers = () => {
    UserDataService.getAllUsers()
      .then((panelUsers: PanelUser[]) => {
        ConsoleLogger(panelUsers);
        setUsers(panelUsers);
        setLoading(false);
      })
      .catch((error) => {
        ConsoleLogger(error);
      });
  };

  useEffect(() => {
    getAllUsers();
    return () => {};
  }, []);

  const handleSnackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setMessage({
      isOpen: false,
      severity: "info",
      message: "",
    });
  };

  const handleGetRowId = (user: any) => user.userId;

  const openAddUserDialogHandler = () => {
    setAddUserDialogOpen(true);
  };

  const closeAddUserDialogHandler = () => {
    setAddUserDialogOpen(false);
  };

  const closeChangePasswordDialogHandler = () => {
    setChangePasswordDialog({ isOpen: false, userId: "", username: "" });
  };

  const handleAddUser = (form: AddUserFormValues) => {
    closeAddUserDialogHandler();
    setLoading(true);
    UserDataService.register(
      form.login,
      form.password,
      form.isAdmin,
      form.customerName,
      +form.firmaoUserId
    )
      .then((user: User) => {
        getAllUsers();
      })
      .catch((e: any) => {
        setLoading(false);
        setMessage({
          isOpen: true,
          severity: "error",
          message: `Create user failed!`,
        });
        ConsoleLogger(e);
      });
  };

  const handlePasswordChange = (userId: string, password: string) => {
    setChangePasswordDialog({ isOpen: false, userId: "", username: "" });
    setLoading(true);

    UserDataService.changePassword(userId, password)
      .then((data) => {
        setLoading(false);
        if (data.success) {
          setMessage({
            isOpen: true,
            severity: "success",
            message: `Password change success!`,
          });
        }
      })
      .catch((error: any) => {
        setLoading(false);
        setMessage({
          isOpen: true,
          severity: "error",
          message: `Password change failed!`,
        });
        ConsoleLogger(error);
      });
  };

  const onCellEditHandler = (
    params: GridCellEditCommitParams,
    event: MuiEvent<MuiBaseEvent>,
    details: GridCallbackDetails
  ) => {
    UserDataService.updateUser(params.id.toString(), {
      [params.field]: params.value,
    })
      .then((data: any) => {
        setMessage({
          isOpen: true,
          severity: "success",
          message: `Update ${params.field} field success!`,
        });
        ConsoleLogger(data);
      })
      .catch((e: any) => {
        setMessage({
          isOpen: true,
          severity: "error",
          message: `Update ${params.field} field failed!`,
        });
      });
  };

  const columns: GridColDef[] = [
    {
      field: "username",
      headerName: "Login (email)",
      minWidth: 250,
      editable: true,
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        const isValid = EmailValidator(params.props.value?.toString() ?? "");
        return { ...params.props, error: !isValid };
      },
    },
    {
      field: "isAdmin",
      headerName: "Admin",
      minWidth: 90,
      editable: true,
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        const isValid =
          params.props.value === "true" || params.props.value === "false";
        return { ...params.props, error: !isValid };
      },
    },
    {
      field: "customerName",
      headerName: "Customer Name",
      minWidth: 250,
      editable: true,
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        const isValid = CustomerNameValidator(
          params.props.value?.toString() ?? ""
        );
        return { ...params.props, error: !isValid };
      },
    },
    {
      field: "firmaoUserId",
      headerName: "Firmao UserId",
      minWidth: 150,
      editable: true,
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        const isValid = UserIdValidator(params.props.value?.toString() ?? "");
        return { ...params.props, error: !isValid };
      },
    },

    {
      field: "actions",
      headerName: "Actions",
      minWidth: 300,
      renderCell: (params: GridRenderCellParams<string>) => (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignContent: "center",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: 16 }}
            onClick={() => {
              setChangePasswordDialog({
                isOpen: true,
                userId: params.row.userId,
                username: params.row.username,
              });
            }}
          >
            Change password
          </Button>
        </Box>
      ),
    },
  ];

  return (
    <PermanentDrawer>
      <Container
        maxWidth={false}
        sx={{
          marginTop: "1rem",
          marginBottom: "1rem",
          backgroundColor: "white",
          width: "100%",
          height: "95%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <h1>User Panel</h1>
          <Box>
            <Button
              variant="contained"
              component="label"
              onClick={openAddUserDialogHandler}
            >
              Add User
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            height: "100%",
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              "& .Mui-error": {
                bgcolor: (theme) =>
                  `rgb(126,10,15, ${theme.palette.mode === "dark" ? 0 : 0.1})`,
                color: (theme) =>
                  theme.palette.mode === "dark" ? "#ff4343" : "#750f0f",
              },
            }}
          >
            <DataGrid
              getRowId={handleGetRowId}
              loading={isLoading}
              columns={columns}
              rows={users}
              onCellEditCommit={onCellEditHandler}
            />
          </Box>
        </Box>
      </Container>
      <AddUserDialog
        isOpen={isAddUserDialogOpen}
        handleClose={closeAddUserDialogHandler}
        handleSubmit={handleAddUser}
      />
      <ChangePasswordDialog
        userId={changePasswordDialog.userId}
        username={changePasswordDialog.username}
        isOpen={changePasswordDialog.isOpen}
        handleClose={closeChangePasswordDialogHandler}
        handleSubmit={handlePasswordChange}
      />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={message.isOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={message.severity}
          sx={{ width: "100%" }}
        >
          {message.message}
        </Alert>
      </Snackbar>
    </PermanentDrawer>
  );
}
