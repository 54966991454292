/* eslint-disable no-unused-vars */
import { Box } from "@mui/material";
import React, { useState } from "react";
import { Basket } from "../../shared/types/3yourmind/basket.type";
import { Line } from "../../shared/types/3yourmind/line.type";
import LineItem from "./LineItem";

type BasketBodyProps = {
  basket: Basket;
  lines: Array<Line>;
  selectedLine: Line | null;
  onLineUpdated: (line: Line) => void;
  onSelectLine: (line: Line) => void;
};

export default function BasketBody({
  basket,
  lines,
  selectedLine,
  onLineUpdated,
  onSelectLine,
}: BasketBodyProps) {
  return (
    <Box
      sx={{
        flex: "1 0 0",
        flexFlow: "column",
        justifyContent: "flex-start",
        width: "100%",
        overflow: "auto",
      }}
    >
      {lines.map((line) => (
        <LineItem
          line={line}
          basketId={basket.id}
          key={line.id}
          isSelected={line.id === selectedLine?.id}
          onLineUpdated={onLineUpdated}
          onSelectLine={onSelectLine}
        />
      ))}
    </Box>
  );
}
