import axios from "axios";


const baseURL = process.env.REACT_APP_ENV === "production" ? "https://api.ta.parts" : "http://localhost:8001";

const unauthorizedAxios = axios.create({
  baseURL
});

const authorizedAxios = axios.create({
  baseURL
});

authorizedAxios.interceptors.request.use((config: any) => {
  const fromLocalStorage = JSON.parse(
    localStorage.getItem("globalState") as string
  );
  const token = fromLocalStorage?.loggedUser?.token ?? "";

  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = token;
  return config;
});


export { unauthorizedAxios, authorizedAxios };
