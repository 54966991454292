import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ConsoleLogger from "../../logs/ConsoleLogger";
import CustomerNameValidator from "../../validation/CustomerNameValidator";
import EmailValidator from "../../validation/EmailValidator";
import PasswordValidator from "../../validation/PasswordValidator";
import UserIdValidator from "../../validation/UserIdValidator";

type AddUserDialogProps = {
  isOpen: boolean;
  handleClose: () => void;
  handleSubmit: (form: AddUserFormValues) => void;
};

export type AddUserFormValues = {
  login: string;
  password: string;
  confirmPassword: string;
  isAdmin: boolean;
  customerName: string;
  firmaoUserId: string;
};

const addUserDefaultValues: AddUserFormValues = {
  login: "",
  password: "",
  confirmPassword: "",
  isAdmin: false,
  customerName: "",
  firmaoUserId: "",
};

export default function AddUserDialog({
  isOpen,
  handleClose,
  handleSubmit,
}: AddUserDialogProps) {
  const [addUserFormValues, setAddUserFormValues] = useState<AddUserFormValues>(
    addUserDefaultValues
  );

  const [errorMessages, setErrorMessages] = useState<AddUserFormValues>(
    addUserDefaultValues
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setAddUserFormValues({ ...addUserFormValues, [id]: value });
  };

  const handleCheckBoxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    setAddUserFormValues({ ...addUserFormValues, [e.target.id]: isChecked });
  };

  const clearFormData = () => {
    setAddUserFormValues(addUserDefaultValues);
    setErrorMessages(addUserDefaultValues);
  };

  const validateData = () => {
    let isValid = true;

    const errors: AddUserFormValues = {
      login: "",
      password: "",
      confirmPassword: "",
      isAdmin: false,
      customerName: "",
      firmaoUserId: "",
    };

    if (EmailValidator(addUserFormValues.login)) {
      errors.login = "";
    } else {
      isValid = false;
      errors.login = "Insert correct email";
    }

    if (PasswordValidator(addUserFormValues.password)) {
      errors.password = "";
    } else {
      isValid = false;
      errors.password =
        "Password must have minimun 8 characters, at least one letter and one number!";
    }

    if (addUserFormValues.password === addUserFormValues.confirmPassword) {
      errors.confirmPassword = "";
    } else {
      isValid = false;
      errors.confirmPassword = "Password should match";
    }

    if (CustomerNameValidator(addUserFormValues.customerName)) {
      errors.customerName = "";
    } else {
      isValid = false;
      errors.customerName = "Min 5, max 100 characters";
    }

    if (UserIdValidator(addUserFormValues.firmaoUserId)) {
      errors.firmaoUserId = "";
    } else {
      isValid = false;
      errors.firmaoUserId = "Firmao User id should be number greater than 0";
    }

    setErrorMessages(errors);

    if (isValid) {
      handleSubmit(addUserFormValues);
    }
  };

  useEffect(() => {
    clearFormData();
    return () => {};
  }, [isOpen]);

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>New user</DialogTitle>
      <DialogContent>
        <TextField
          error={errorMessages.login.length > 0}
          margin="dense"
          id="login"
          label="Login (email)"
          type="email"
          value={addUserFormValues.login}
          onChange={handleInputChange}
          helperText={errorMessages.login}
          fullWidth
          inputProps={{ maxLength: 50 }}
          variant="outlined"
        />

        <TextField
          error={errorMessages.password.length > 0}
          margin="dense"
          id="password"
          label="Password"
          type="password"
          value={addUserFormValues.password}
          onChange={handleInputChange}
          helperText={errorMessages.password}
          fullWidth
          inputProps={{ maxLength: 40 }}
          variant="outlined"
        />
        <TextField
          error={errorMessages.confirmPassword.length > 0}
          margin="dense"
          id="confirmPassword"
          label="Confirm Password"
          type="password"
          value={addUserFormValues.confirmPassword}
          onChange={handleInputChange}
          helperText={errorMessages.confirmPassword}
          fullWidth
          inputProps={{ maxLength: 40 }}
          variant="outlined"
        />

        <TextField
          error={errorMessages.customerName.length > 0}
          margin="dense"
          id="customerName"
          label="Customer Name"
          type="text"
          value={addUserFormValues.customerName}
          onChange={handleInputChange}
          helperText={errorMessages.customerName}
          fullWidth
          inputProps={{ maxLength: 100 }}
          variant="outlined"
        />

        <TextField
          error={errorMessages.firmaoUserId.length > 0}
          margin="dense"
          id="firmaoUserId"
          label="Firmao UserId"
          type="text"
          value={addUserFormValues.firmaoUserId}
          onChange={handleInputChange}
          helperText={errorMessages.firmaoUserId}
          fullWidth
          inputProps={{ maxLength: 50 }}
          variant="outlined"
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={addUserFormValues.isAdmin}
              id="isAdmin"
              onChange={handleCheckBoxChange}
            />
          }
          label="Is Admin"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={validateData}>Save</Button>
      </DialogActions>
    </Dialog>
  );
}
