import { Logout } from "@mui/icons-material";
import {
  Box,
  Tooltip,
  IconButton,
  Avatar,
  Typography,
  Menu,
  MenuItem,
  ListItemIcon,
} from "@mui/material";
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { globalContext } from "../../store/global-store";

type UserMenuProps = {
  boxSx: any;
};

export default function UserMenu({ boxSx }: UserMenuProps) {
  const { globalState, dispatch } = useContext(globalContext);
  const history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const logoutHandler = () => {
    history.push("/login");
    dispatch({ type: "PURGE_STATE" });
  };

  return (
    <>
      <Box sx={boxSx}>
        <Tooltip title="Account settings">
          <IconButton onClick={handleClick} size="small" sx={{ ml: 2 }}>
            <Avatar sx={{ width: 32, height: 32 }}>
              {globalState.loggedUser?.username[0]}
            </Avatar>
          </IconButton>
        </Tooltip>
        <Typography
          sx={{
            pl: "10px",
            pr: "10px",
            maxWidth: "70%",
            overflow: "hidden",
          }}
        >
          {globalState.loggedUser?.username}
        </Typography>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "bottom" }}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <MenuItem onClick={logoutHandler}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
}
