import React, { useContext, useState } from "react";

import {Box, Button, CircularProgress, Typography} from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import { globalContext } from "../../store/global-store";
import FileService from "../../api/FileService";
import ConsoleLogger from "../../logs/ConsoleLogger";

type FileUploadBoxProps = {
  projectIdProp: string | null;
  basketIdProp: number | null;
  onUploadCompleted: (() => void) | null;
};

export default function FileUploadBox({
  projectIdProp,
  basketIdProp,
  onUploadCompleted,
}: FileUploadBoxProps) {
  const history = useHistory();
  const location = useLocation();

  const { globalState } = useContext(globalContext);
  const [selectedFile, setSelectedFile] = useState<File | undefined>();
  const [isSelected, setIsSelected] = useState(false);
  const [getProgress, setProgress] = useState("0%");
  const [isWorking, setIsWorking] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  let getFileStatusTimeout: any;

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file: File | undefined | null = event.target.files?.item(0);

    if (file !== null) {
      setSelectedFile(file);
      setIsSelected(true);
      setProgress("0%");
    }
  };

  const moveToBasketDetails = (projectId: string, basketId: number) => {
    history.push(`/projects/${projectId}/baskets/${basketId}`);
  };

  const getFileStatus = (
    fileId: string,
    projectId: string,
    basketId: number
  ) => {
    FileService.getFileStatus(fileId)
      .then((status: string) => {
        setProgress(status);
        ConsoleLogger(status);
        if (status === "finished") {
          moveToBasketDetails(projectId, basketId);
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          onUploadCompleted && onUploadCompleted();
          clearTimeout(getFileStatusTimeout);
        } else {
          getFileStatusTimeout = setTimeout(() => {
            getFileStatus(fileId, projectId, basketId);
          }, 1000);
        }
      })
      .catch((error: any) => {
        clearTimeout(getFileStatusTimeout);
        setProgress("error");
        ConsoleLogger(error);
      });
  };

  const handleSubmission = (file: File) => {
    setErrorMessage('');
    setIsWorking(true);
    FileService.uploadFile(
      file.name,
      "mm",
      "de",
      file,
      (event) => {
        ConsoleLogger(event);
        const progressPercentage = Math.round(
          (100 * event.loaded) / event.total
        );
        setProgress(`${progressPercentage}%`);
      },
      basketIdProp,
      projectIdProp
    )
      .then((response: any) => {
        if (response === undefined) {throw new Error('Invalid file selected')}
        ConsoleLogger(response.data);
        const { projectId, fileId, basketId, lineId } = response.data;
        getFileStatus(fileId, projectId, basketId);
        ConsoleLogger(response);
      })
      .catch((e) => {
        setIsWorking(false);
        setErrorMessage(e.message)
        ConsoleLogger(e);
      });
  };

  return (
    <Box
      sx={{
        p: 4,
        width: "100%",
        border: "3px dashed gray",
        borderRadius: "10px",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <Button variant="contained" component="label" sx={{ margin: 1 }} disabled={isWorking}>
        Wybierz plik
        <input type="file" onChange={changeHandler} hidden />
      </Button>
      {isSelected ? (
        <Box sx={{ alignItems: "center", textAlign: "center" }}>
          <Typography>Filename: {selectedFile?.name}</Typography>
          <Typography>Filetype: {selectedFile?.type}</Typography>
          <Typography>Size in bytes: {selectedFile?.size}</Typography>
          <Typography>
            Last modified date:{" "}
            {new Date((selectedFile as any).lastModified).toLocaleDateString()}
          </Typography>
          <Typography>Progress: {getProgress}</Typography>
          <Button
            variant="contained"
            sx={{ margin: 1 }}
            onClick={() => handleSubmission(selectedFile!)}
            disabled={isWorking}
          >
            Wyślij
          </Button>
          {isWorking && (
            <Box>
              <CircularProgress/>
            </Box>
          )}
          {errorMessage !== '' && (
            <Typography>{errorMessage}</Typography>
          )}
        </Box>
      ) : (
        <Typography> Select a file to show details </Typography>
      )}
    </Box>
  );
}
