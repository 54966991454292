/* eslint-disable no-restricted-globals */
import { Box, Button, TextField } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import ConsoleLogger from "../logs/ConsoleLogger";

type CounterProps = {
  initialCount: number;
  onCountChange: (count: number) => void;
};

export default function CountSelector({
  initialCount,
  onCountChange,
}: CounterProps) {
  const [getCount, setCount] = useState<string>(initialCount.toString());
  const [getTimer, setTimer] = useState<any>(null);
  const isFirstRun = useRef(true);

  const handleCountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!Number.isNaN(parseInt(event.target.value, 10))) {
      setCount(event.target.value);
    } else {
      setCount("");
    }
  };

  const handleSetCounter = (counter: string) => {
    const value = parseInt(counter, 10);
    if (value > 0) {
      setCount(counter.toString());
    }
  };

  const handleAdd = () => {
    const newValue = parseInt(getCount, 10) + 1;
    handleSetCounter(newValue.toString());
  };

  const handleSubtract = () => {
    const newValue = parseInt(getCount, 10) - 1;
    handleSetCounter(newValue.toString());
  };

  useEffect(() => {
    clearTimeout(getTimer);

    if (!isFirstRun.current) {
      setTimer(
        setTimeout(() => {
          const value = parseInt(getCount, 10);
          onCountChange(value);
        }, 500)
      );
    }

    isFirstRun.current = false;
  }, [getCount]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        textAlign: "right",
        gap: "0.5rem",
        whiteSpace: "nowrap",
        padding: "0.5rem",
        maxHeight: "3rem",
        border: "1px solid rgba(0, 0, 0, 0.12)",
      }}
    >
      <Button
        sx={{ maxWidth: "3rem", minWidth: "0px" }}
        size="small"
        variant="outlined"
        onClick={handleSubtract}
      >
        -
      </Button>
      <TextField
        sx={{
          width: "3rem",
          minWidth: "0px",
          minHeight: "0px",
          height: "2rem",
          padding: "0px",
        }}
        value={getCount}
        variant="standard"
        onChange={handleCountChange}
      />
      <Button
        sx={{ maxWidth: "3rem", minWidth: "0px" }}
        size="small"
        variant="outlined"
        onClick={handleAdd}
      >
        +
      </Button>
    </Box>
  );
}
